import { Suspense, useEffect, useState} from 'react';
import Garden from './components/3D/Garden/Scene';
import Room from './components/3D/Room/Room';
import { AnimatePresence } from 'framer-motion';
import SceneLocationWrapper from './components/3D/SceneLocationWrapper';

const Scene = (props) => {

  const { windowDimensions, location, previewItem, rooms, overlayContent, setOverlayContent, mapIsActive } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  useEffect(() => {
    if (location.pathname) {
      setOverlayContent({});
    }
  }, [location.pathname, setOverlayContent]);

  return (
    <div className={`canvas__outer${mapIsActive === true ? ' map-active' : ''}`}
      style={{
        height: windowHeight + 'px',
        width: windowWidth + 'px',
        backgroundColor: 'white'
      }}
    >
      <AnimatePresence exitBeforeEnter={true}>
      {
        location.pathname === '/' && previewItem.collection !== 'rooms' && previewItem.collection !== 'pages2DOnly' &&
        <SceneLocationWrapper
          sceneIsLoaded={sceneIsLoaded}
          setSceneIsLoaded={setSceneIsLoaded}
          key={'garden'}
          {...props}
          overlayContent={overlayContent}
          setOverlayContent={setOverlayContent}
        >
          <Suspense fallback={null}>
            <group position={[0, 0, 0]}>
              <Garden sceneIsLoaded={sceneIsLoaded} {...props} />
            </group>
          </Suspense>
        </SceneLocationWrapper>
      }
      {
        rooms &&
        rooms[0] &&
        rooms.map(
          (room, i) => (
            (
              (location.pathname === `/room/${room.title_slug}` && room.title_slug && room.title_slug !== '') ||
              (location.pathname === `/room/${room._id}`)
            ) &&
            <SceneLocationWrapper
              sceneIsLoaded={sceneIsLoaded}
              setSceneIsLoaded={setSceneIsLoaded}
              key={room.title_slug + i + '3d'}
              {...props}
              overlayContent={overlayContent}
              setOverlayContent={setOverlayContent}
            >
              <Room sceneIsLoaded={sceneIsLoaded} room={room} {...props} />
            </SceneLocationWrapper>
          )
        )
      }
      {
        previewItem.collection === 'rooms' && location.search === '?preview=true' &&
        <SceneLocationWrapper sceneIsLoaded={sceneIsLoaded} setSceneIsLoaded={setSceneIsLoaded} key={'rooms preview'} {...props} >
          <Suspense fallback={null}>
            <Room sceneIsLoaded={sceneIsLoaded} room={previewItem} {...props} />
          </Suspense>
        </SceneLocationWrapper>
      }
      {
        location.pathname === '/offlinetest' &&
        <SceneLocationWrapper sceneIsLoaded={sceneIsLoaded} setSceneIsLoaded={setSceneIsLoaded} key={'rooms preview offline'} {...props} >
          <Suspense fallback={null}>
            <Room
              sceneIsLoaded={sceneIsLoaded}
              room={{
                content: [],
                wallsType: null,
                entryPositionX: 0,
                entryPositionZ: 0,
                entryLookAtX: 0,
                  entryLookAtZ: 0,
                  width: 600,
                  depth: 600,
                floorColor: '#000000'
              }}
              {...props}
              overlayContent={overlayContent}
              setOverlayContent={setOverlayContent}
            />
          </Suspense>
        </SceneLocationWrapper>
      }
      </AnimatePresence>
    </div>
  );
}

export default Scene;