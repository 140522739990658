const PageGridConditionalSectionWrapper = (props) => {

  const { section, children } = props;

  if (!section) {
    return (
      <div className="row page__item__content__section">
        {children}
      </div>
    );
  } else {
    return children;
  }
}

export default PageGridConditionalSectionWrapper;