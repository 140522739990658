import { useRef, useState, useEffect } from 'react';
import { useGLTF, useTexture, Text } from '@react-three/drei';
import { TextureLoader, EquirectangularRefractionMapping, sRGBEncoding, DoubleSide } from 'three';
import PlayHouseModel from '../../../../assets/models/playhouse.glb';
import windowReflection from '../../../../assets/textures/window-reflection.jpg';
import { A11y } from '@react-three/a11y';
import knockingSoundEffect from '../../../../assets/sounds/door-knock-sound-effect.mp3';
import parchment from '../../../../assets/map/parchment.jpg';
import alpha from '../../../../assets/map/alpha.jpg';
import AppleChancery from '../../../../assets/fonts/AppleChancery.woff';

const PlayHouseConditionalA11yWrapper = (props) => {

  const { isClickable, children } = props;

  if (isClickable !== false) {
    return (
      <A11y role="button" description={`Knock on the door of the house?`}>
        {children}
      </A11y>
    );
  } else {
    return children;
  }
}

const PlayHouseMesh = (props) => {
  const { modelClone, isHoldingPage, modelMesh, isPreview, isClickable, rooms, setToolTip } = props;

  if (modelClone.uuid) {
    return (
      <PlayHouseConditionalA11yWrapper isClickable={isClickable}>
        <mesh
          ref={modelMesh}
          name={'house'}
          onPointerDown={() => {
            if (isClickable !== false) {
              if (isHoldingPage === true || isPreview === true) {
                const audio = document.createElement('audio');
                audio.src = knockingSoundEffect;
                audio.play()
                  .catch();
              } else {
                const audio = document.createElement('audio');
                let firstRoomLink = '';
                if (rooms) {
                  const firstRoom = rooms[0];
                  if (firstRoom) {
                    firstRoomLink = `/room/${firstRoom.title_slug && firstRoom.title_slug !== '' ? firstRoom.title_slug : firstRoom._id}`;
                  }
                }
                audio.src = knockingSoundEffect;
                audio.play()
                  .then(() => {
                    audio.addEventListener('ended', () => {
                      firstRoomLink !== '' && props.history.push(firstRoomLink);
                    })
                  })
                  .catch(() => {
                    firstRoomLink !== '' && props.history.push(firstRoomLink);
                  });
              }
            }
          }}
          onPointerOver={() => {
            setToolTip('Knock on the door');
          }}
          onPointerOut={() => {
            setToolTip('');
          }}
        >
          <primitive object={modelClone} />
        </mesh>
      </PlayHouseConditionalA11yWrapper>
    );
  } else {
    return null;
  }
}

const PlayHouse = (props) => {

  const model = useGLTF(PlayHouseModel);
  const { isHoldingPage } = props;
  const [modelClone, setModelClone] = useState({});
  const modelMesh = useRef();
  const woodGroup = useRef();

  useEffect(() => {

    let isMounted = true;
    let raf;
    let reflectionTexture;

    if (model.scene) {
      const traverseMesh = () => {

        let isWoodAdded = false;

        if (model.scene) {
          model.scene.traverse(
            function (child) {
              if (child.isMesh) {
                if (child.material) {
                  if (child.material.name === 'Door') {
                    if (woodGroup.current?.children && isWoodAdded === false) {
                      for (let wooden of woodGroup.current.children) {
                        wooden.material = child.material.clone();
                      }
                      woodGroup.current.visible = true;
                      isWoodAdded = true;
                    }
                  }
                  if (child.material.name === 'Glass') {
                    child.material.needsUpdate = true;
                    child.material.envMap = reflectionTexture;
                    child.material.envMap.mapping = EquirectangularRefractionMapping;
                    child.material.envMap.encoding = sRGBEncoding;
                    child.material.metalness = 0.99;
                  }
                }
              }
            }
          );
        } else {
          raf = requestAnimationFrame(traverseMesh);
        }
      }

      reflectionTexture = new TextureLoader().load(
        windowReflection,
        () => {
          raf = requestAnimationFrame(traverseMesh);
        }
      );

      if (isMounted === true) {
        setModelClone(model.scene.clone());
      }
    }
    return () => {
      cancelAnimationFrame(raf);
      isMounted = false;
    }
  }, [model.scene]);

  const map = useTexture(parchment);
  const alphaMap = useTexture(alpha);

  return (
    <group
      position={[-120.5, -16, -140]}
      scale={[240, 240, 240]}
      name={'house group'}
    >
      {
        modelClone.uuid &&
        <group
          rotation={[0, 0, 0]}
        >
          <PlayHouseMesh modelMesh={modelMesh} isHoldingPage={isHoldingPage} modelClone={modelClone} {...props} />
        </group>
      }
      <group position={[-3.62, 2.5, -1.9]}>
        <pointLight position={[0, 120, 0]} intensity={0.2} />
        <pointLight position={[0, 0, 60]} intensity={0.2} />
        <pointLight position={[-60, 0, 0]} intensity={0.2} />
        <pointLight position={[60, 0, 0]} intensity={0.2} />
        <pointLight position={[0, 0, -60]} intensity={0.2} />
      </group>
      {
        isHoldingPage === true &&
        <group position={[0, 0, 0]} scale={[0.06, 0.06, 0.06]} rotation={[0, 0, 0]}>
          {/* sign on house */}
          <group rotation={[0, 0, 0.05]} position={[1.2, 1.9, 1.58]}>
            <mesh>
              <planeBufferGeometry args={[0.7, 1.1]} />
              <meshPhongMaterial
                side={DoubleSide}
                transparent={true}
                map={map}
                alphaMap={alphaMap}
              />
            </mesh>
            <Text
              color="black"
              anchorX="center"
              anchorY="bottom"
              fontSize={0.1}
              maxWidth={1}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'center'}
              font={AppleChancery}
              position={[0, 0.2, 0.01]}
            >Gone fishing</Text>
          </group>
        </group>
      }
    </group>
  )
}

export default PlayHouse;