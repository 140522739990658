import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { A11yAnnouncer } from '@react-three/a11y';
import Overlay from './Overlay/Overlay';
import Loader from './Loader';
import { GracefulDegradation } from '../../utils/ThreeHelpers/SceneResizer';
import FPSLimiter from '../../utils/ThreeHelpers/FPSLimiter';
import Instructions from './Instructions';
import ToolTip from './ToolTip';

const SceneLocationWrapper = (props) => {

  const { windowDimensions, children, sceneIsLoaded, setSceneIsLoaded, setInstructionsAreActive, instructionsAreActive, instructionsHaveShownOnce, setInstructionsHaveShownOnce,
    toolTip, setToolTip, location, mapIsActive, is3D, isPreview, overlayContent } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [fps] = useState(24);

  useEffect(() => {
    setSceneIsLoaded(false);
  }, [setSceneIsLoaded]);

  useEffect(() => {
    if (instructionsHaveShownOnce === false) {
      setInstructionsHaveShownOnce(true);
      setInstructionsAreActive(true);
    }

    const handleClick = (e) => {
      if (e.target.closest('.canvas__inner') && sceneIsLoaded === true) {
        setInstructionsAreActive(false);
        window.removeEventListener('click', handleClick);
      }
    }

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    }
  }, [setInstructionsAreActive, instructionsHaveShownOnce, setInstructionsHaveShownOnce, sceneIsLoaded]);

  useEffect(() => {
    if (location.pathname) {
      setToolTip('');
    }
  }, [setToolTip, location.pathname]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
      className="canvas__wrapper"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <div
        className="canvas__inner"
        style={{
          width: windowWidth + 'px',
          height: windowHeight + 'px',
          pointerEvents: sceneIsLoaded === true ? 'auto' : 'none'
        }}
      >
        <Canvas
          camera={{ far: 10000 }}
        >
          {
            sceneIsLoaded === true &&
            <GracefulDegradation {...props} fps={mapIsActive === true || overlayContent.type ? 1 : fps} />
          }
          {
            sceneIsLoaded === true &&
            <FPSLimiter {...props} fps={mapIsActive === true || overlayContent.type ? 1 : fps} />
          }
          {
            sceneIsLoaded === false &&
            <Loader {...props} setSceneIsLoaded={setSceneIsLoaded} />
          }
          {children}
        </Canvas>
        <A11yAnnouncer />
        <AnimatePresence>
          {
            sceneIsLoaded === false &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`loader${props.welcomeScreenIsActive === true ? ' welcome' : ''}`}
            >
              <p className="loader__text">Loading...</p>
            </motion.div>
          }
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {
          props.overlayContent.type &&
          <Overlay {...props} />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          instructionsAreActive === true && sceneIsLoaded === true && mapIsActive === false && isPreview === false &&
          <Instructions {...props} />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          toolTip !== '' && sceneIsLoaded === true && mapIsActive === false && is3D === true &&
          <ToolTip {...props} />
        }
      </AnimatePresence>
    </motion.div>
  )
}

export default SceneLocationWrapper;