import { motion } from 'framer-motion';

const ToolTip = (props) => {

  const { toolTip } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{opacity: 0}}
      className = "nav--3d-tooltip"
    >
      <div className="nav--3d-tooltip__content">
        <p>{toolTip}</p>
      </div>
    </motion.div>
  )
}

export default ToolTip;