import { useEffect } from 'react';

const Light = (props) => {
  const { item, room, roomHeight, setWidth, setHeight, setDepth, positionX, positionY, positionZ } = props;
  const { value } = item;
  const { brightness, color } = value;

  useEffect(() => {
    setWidth(1);
    setHeight(1);
    setDepth(1);
  }, [setWidth, setHeight, setDepth]);

  return (
    <pointLight
      castShadow={true}
      position={[
        positionX / 100 * room.width,
        positionY / 100 * roomHeight,
        positionZ / 100 * room.depth
      ]}
      decay={2}
      intensity={parseFloat(brightness) / 100}
      color={color}
    />
  )
}

export default Light;