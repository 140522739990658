import { useRef, useState, useEffect, Fragment } from 'react';
import { cockpitUrl, getImage } from '../../../utils/cockpit';
import { motion } from 'framer-motion';
import PageLayoutGridView from './PageLayoutGridView';
import PageSlideshowView from './PageSlideshowView/PageSlideshowView';
import DraggableItems from './DraggableItems';
import CustomCursor from './CustomCursor';
import { Helmet } from 'react-helmet';

const Page = (props) => {
  const { item, windowDimensions, siteOptions } = props;
  const { windowHeight } = windowDimensions;
  const { content2DLayout, backgroundType2D, backgroundColor2D, backgroundImage2D, backgroundImageSize2D, customCursor, customCursorClickPositionX, customCursorClickPositionY, imageHeader, imageFooter, imageHeaderStyle, imageFooterStyle } = item;
  const [backgroundImageFullPath, setBackgroundImageFullPath] = useState('');
  const [cursorSrc, setCursorSrc] = useState('');
  const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0});
  const container = useRef();

  useEffect(() => {
    if (backgroundType2D && backgroundType2D !== '' && backgroundType2D !== 'colour' && backgroundImage2D) {
      getImage(backgroundImage2D.path, null, null, 69, setBackgroundImageFullPath);
    }
  }, [backgroundType2D, backgroundImage2D]);

  return (
    <Fragment>
      <Helmet>
        <title key="title">{`${siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow'} | ${item.title}`}</title>
        <meta property="title" content={`${siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow'} | ${item.title}`} />
        <meta property="og:title" content={`${siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow'} | ${item.title}`} />
        <meta property="twitter:title" content={ `${ siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow' } | ${ item.title }` } />
        <meta property="description" content={ item.seoDescription ?? siteOptions.seoDescription ?? 'Claire Barrow' } />
        <meta property="og:description" content={ item.seoDescription ?? siteOptions.seoDescription ?? 'Claire Barrow' } />
        <meta property="twitter:description" content={ item.seoDescription ?? siteOptions.seoDescription ?? 'Claire Barrow' } />
      </Helmet>
      <motion.div
        key='page'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.9,
          ease: 'easeInOut'
        }}
        style={{
          height: windowHeight + 'px',
          backgroundColor: backgroundColor2D,
          backgroundImage: backgroundType2D && backgroundType2D !== '' && backgroundType2D !== 'colour' && backgroundImageFullPath !== '' ? `url(${backgroundImageFullPath})` : null,
          backgroundSize: backgroundType2D === 'repeat pattern' ? `${backgroundImageSize2D}%` : 'cover',
          backgroundPosition: 'center center',
          cursor: cursorSrc !== '' ? `url(${cursorSrc}) ${customCursorClickPositionX ? customCursorClickPositionX : 0} ${customCursorClickPositionY ? customCursorClickPositionY : 0}, default` : null,
          paddingTop: imageHeader?.path ? '120px' : null,
          paddingBottom: imageFooter?.path ? '120px' : null
        }}
        className={`view view--page${cursorSrc !== '' ? ' view--page--customCursor' : ''}`}
        ref={container}
      >
        {
          customCursor?.path && customCursor.path !== '' &&
          <CustomCursor
            cursorPath={customCursor.path}
            customCursorClickPositionX={customCursorClickPositionX}
            customCursorClickPositionY={customCursorClickPositionY}
            setCursorSrc={setCursorSrc}
            cursorCoords={cursorCoords}
            setCursorCoords={setCursorCoords}
          />
        }
        {
          imageHeader?.path &&
          <img
            className="image-header"
            src={cockpitUrl + imageHeader.path}
            alt={imageHeader.title}
            style={{
              objectFit: imageHeaderStyle === 'centre' ? 'cover' : 'contain',
              backgroundImage: imageHeaderStyle === 'repeat' ? `url(${cockpitUrl + imageHeader.path})` : null,
              backgroundSize: imageHeaderStyle === 'repeat' ? `auto 100%` : null,
              objectPosition: imageHeaderStyle === 'repeat' ? '0 120px' : 'center',
              backgroundRepeat: imageHeaderStyle === 'repeat' ? 'repeat-x' : null
            }}
          />
        }
        {
          (content2DLayout === 'layout grid with vertical scroll' || props.is2DOnlyPage === true) &&
          <PageLayoutGridView {...props} />
        }
        {
          content2DLayout === 'slideshow' &&
          <PageSlideshowView {...props} />
        }
        {
          imageFooter?.path &&
          <img
            className="image-footer"
            src={cockpitUrl + imageFooter.path}
            alt={imageFooter.title}
            style={{
              objectFit: imageFooterStyle === 'centre' ? 'cover' : 'contain',
              backgroundImage: imageFooterStyle === 'repeat' ? `url(${cockpitUrl + imageFooter.path})` : null,
              backgroundSize: imageFooterStyle === 'repeat' ? `auto 100%` : null,
              objectPosition: imageFooterStyle === 'repeat' ? '0 120px' : null,
              backgroundRepeat: imageFooterStyle === 'repeat' ? 'repeat-x' : null
            }}
          />
        }
        <DraggableItems container={container} {...props} />
      </motion.div>
    </Fragment>
  );
}

export default Page;