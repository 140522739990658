import { useTexture } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import signMap from '../../../../assets/textures/sold-sign.jpg';

const SoldSign = (props) => {

  const signTexture = useTexture(signMap);

  return (
    <group
      position={[-20, -6, -95]}
      scale={[1, 1, 1]}
    >
      <mesh>
        <cylinderBufferGeometry args={[0.4, 0.4, 28, 24]} />
        <meshPhysicalMaterial metalness={0.9} roughness={0.6} />
      </mesh>
      <group
        position={[-0.35, 10, 0.6]}
        rotation={[0, -Math.PI, 0]}
      >
        <A11y role="button" description={`Click to make an enquiry`} >
          <mesh
            onClick={() => {
              window.open('mailto:studio@clairebarrow.co.uk');
            }}
          >
            <boxBufferGeometry args={[12, 9, 0.1]} />
            <meshPhysicalMaterial metalness={0.2} attachArray="material" />
            <meshPhysicalMaterial metalness={0.2} attachArray="material" />
            <meshPhysicalMaterial metalness={0.2} attachArray="material" />
            <meshPhysicalMaterial metalness={0.2} attachArray="material" />
            <meshPhysicalMaterial metalness={0.2} attachArray="material" />
            <meshPhysicalMaterial metalness={0.2} attachArray="material">
              <primitive attach="map" object={signTexture} anisotropy={8}
              format={THREE.RGBFormat}
                encoding={THREE.sRGBEncoding}
              />
            </meshPhysicalMaterial>
          </mesh>
        </A11y>
      </group>
    </group>
  )
}

export default SoldSign;