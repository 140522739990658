import { DoubleSide } from 'three';

const FloorHover = (props) => {
  const { floorHover } = props;

  return (
    <mesh ref={floorHover} position={[0, -9.8, 0]} rotation={[Math.PI * 0.5, 0, 0]}>
      <circleGeometry args={[12, 32]} />
      <meshBasicMaterial color={0xffffff} side={DoubleSide} />
    </mesh>
  );
}

export default FloorHover;