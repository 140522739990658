import PageItemSectionGrid from './PageItemSectionGrid';
import PageItemSectionImage from './PageItemSectionImage';
import PageItemSectionCustomImage from './PageItemSectionCustomImage';
import PageItemSectionText from './PageItemSectionText';
import PageItemSectionGallery from './PageItemSectionGallery';
import PageItemSectionHtml from './PageItemSectionHtml';
import PageItemSectionShopifyBuyButton from './PageItemSectionShopifyBuyButton';
import PageItemSectionButton from './PageItemSectionButton';
import PageItemSectionHeading from './PageItemSectionHeading';
import PageItemSection from './PageItemSection';
import PageItemSectionVideo from './PageItemSectionVideo';
import PageItemSectionMailchimpSignupForm from './PageItemSectionMailchimpSignupForm';
import PageItemSectionButton3D from './PageItemSectionButton3D';
import PageItemSectionVerticalPadding from './PageItemSectionVerticalPadding';
import PageItemSectionCustomText from './PageItemSectionCustomText';
import PageItemSectionKeypad from './PageItemSectionKeypad';

const PageItemSectionItem = (props) => {

  const { item, setIs3D } = props;
  const { component } = item;

  switch (component) {
    case 'grid':
      return <PageItemSectionGrid setIs3D={setIs3D} {...props} item={item} />
    case 'image':
      return <PageItemSectionImage {...props} item={item} />
    case 'customImage':
      return <PageItemSectionCustomImage {...props} item={item} />
    case 'text':
      return <PageItemSectionText {...props} item={item} />
    case 'gallery':
      return <PageItemSectionGallery {...props} item={item} />
    case 'html':
      return <PageItemSectionHtml {...props} item={item} />
    case 'shopifyBuyButton':
      return <PageItemSectionShopifyBuyButton {...props} item={item} />
    case 'button':
      return <PageItemSectionButton {...props} item={item} />
    case 'heading':
      return <PageItemSectionHeading {...props} item={item} />
    case 'section':
      return <PageItemSection setIs3D={setIs3D} {...props} section={item} />
      case 'video':
      return <PageItemSectionVideo {...props} item={item} />
    case 'video_embed':
      return <PageItemSectionVideo {...props} item={item} />
    case 'newsletterSignup':
      return <PageItemSectionMailchimpSignupForm {...props} item={item} />
    case 'button3D':
      return <PageItemSectionButton3D {...props} item={item} setIs3D={setIs3D} />
    case 'verticalPadding':
      return <PageItemSectionVerticalPadding {...props} item={item} />
    case 'customText':
      return <PageItemSectionCustomText {...props} item={item} />
    case 'keypad':
      return <PageItemSectionKeypad {...props} item={item} />
    default:
      return null;
  }
}

export default PageItemSectionItem;