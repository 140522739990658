import { PerspectiveCamera } from '@react-three/drei';

const Camera = (props) => {
  const { camera, cameraChildren, windowWidth, windowHeight, location, room } = props;

  return (
    <PerspectiveCamera
      makeDefault
      ref={camera}
      fov={42 / (windowWidth * 0.8) * windowHeight * 1.2}
      position={[
        room?.entryPositionX ? room.entryPositionX : 0,
        location.pathname === '/' ? 20 : 10,
        location.pathname === '/' ? 40 : room?.entryPositionZ ? room.entryPositionZ : 0
      ]}
      rotation={[0.2, 0, 0]}
      near={1} far={10000}
    >
      {cameraChildren && cameraChildren}
    </PerspectiveCamera>
  );
}

export default Camera;