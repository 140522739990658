import { useRef, useState } from "react";
import { motion } from 'framer-motion';
import LazyloadImage from '../../LazyloadImage';

const DraggableItem = (props) => {

  const { draggableItem, windowWidth, windowHeight } = props;
  const { image, size, constrainedToPage } = draggableItem;
  const modal = useRef();
  const [x] = useState(Math.floor(Math.random() * 100));
  const [y] = useState(Math.floor(Math.random() * 100));

  if (image?.path) {
    return (
      <motion.div
        ref={modal}
        className={`draggable draggable-item ${size}`}
        drag={true}
        dragConstraints={constrainedToPage === true ?
          {
            top: 0 - (y / 100 * windowHeight),
            left: 0 - (x / 100 * windowWidth),
            bottom: windowHeight - (y / 100 * windowHeight) - 240,
            right: windowWidth - (x / 100 * windowWidth) - 240
          }
          :
          null
        }
        style={{
          top: y / 100 * windowHeight + 'px',
          left: x / 100 * windowWidth + 'px'
        }}
        transition={{
          duration: 0.4,
          delay: 0
        }}
      >
        <div>
          <LazyloadImage image={image} />
        </div>
      </motion.div>
    );
  } else {
    return null;
  }
}

const DraggableItems = (props) => {

  const { item, windowHeight } = props;
  const { draggableItems } = item;
  const container = useRef();

  if (draggableItems && draggableItems[0]) {
    return (
      <div
        ref={container}
        className="draggables__wrapper"
        style={{
          height: windowHeight + 'px'
        }}
      >
        {
          draggableItems &&
          draggableItems.map(
            (draggableItem, i) => (
              <DraggableItem
                key={i}
                draggableItem={draggableItem.value}
                {...props}
              />
            )
          )
        }
      </div>
    );
  } else {
    return null;
  }
}

export default DraggableItems;