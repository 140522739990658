const PageItemSectionVerticalPadding = (props) => {

  const { item, windowWidth } = props;
  const { settings } = item;
  const { amountMobile, amountDesktop } = settings;

  if (amountMobile && amountDesktop) {
    return <div className="page__item__verticalPadding col-xxs-12" style={{ width: '100%', height: windowWidth < 768 ? amountMobile + 'px' : amountDesktop + 'px' }} />;
  } else {
    return null;
  }
}

export default PageItemSectionVerticalPadding;