const DimensionSwitch = (props) => {

  const { is3D, setIs3D } = props;

  return (
    <div className="dimension-switch">
      <button
        className={`dimension-switch__button switch-to-${is3D === true ? '2D' : '3D'}`}
        aria-label={`View site in ${is3D === true ? '3D' : '2D'}`}
        onClick={() => setIs3D(!is3D)}
      >
        <span>{is3D === true ? '2D' : '3D'}</span>
      </button>
    </div>
  )
}

export default DimensionSwitch;