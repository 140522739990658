import { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { BackSide, TextureLoader, EquirectangularReflectionMapping, sRGBEncoding } from 'three';
import MapContent from './MapContent';
import windowReflection from '../../../assets/textures/Pool.gif';
import normal from '../../../assets/textures/sand-normal.jpg';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import SceneResizer, { GracefulDegradation } from '../../utils/ThreeHelpers/SceneResizer';
import FPSLimiter from '../../utils/ThreeHelpers/FPSLimiter';

const Camera = (props) => {
  const { camera } = props;

  return (
    <PerspectiveCamera
      makeDefault
      ref={camera}
      position={[0, 0, 7.1]}
      near={0.1}
      far={1000000}
      rotation={[0, 0, 0]}
    >
    </PerspectiveCamera>
  )
}

const MapScene = (props) => {

  const { setTexturesAreLoaded } = props;
  const group = useRef();
  const material = useRef();
  const mapGroup = useRef();
  const wrapper = useRef();
  const controls = useRef();
  const camera = useRef();

  useEffect(() => {

    setTexturesAreLoaded(false);
    let reflectionTexture;
    let texturesLoaded = 0;

    reflectionTexture = new TextureLoader().load(
      normal,
      () => {
        if (material.current) {
          material.current.needsUpdate = true;
          material.current.envMap = reflectionTexture;
          material.current.envMap.mapping = EquirectangularReflectionMapping;
          material.current.envMap.encoding = sRGBEncoding;
          texturesLoaded++;
          if (texturesLoaded === 2) {
            setTexturesAreLoaded(true);
          }
        }
      }
    );

    let mapTexture;

    mapTexture = new TextureLoader().load(
      windowReflection,
      () => {
        if (material.current) {
          material.current.needsUpdate = true;
          material.current.normalMap = mapTexture;
          texturesLoaded++;
          if (texturesLoaded === 2) {
            setTexturesAreLoaded(true);
          }
        }
      }
    );
  }, [setTexturesAreLoaded]);

  useFrame(({ mouse }) => {
    if (group.current) {
      group.current.rotation.set((Math.PI) * mouse.x, 0, 0);
    }
  });

  return (
    <group ref={wrapper}>
      <Camera {...props} camera={camera} />
      <group ref={group}>
          <pointLight intensity={0.9} position={[0, 0, -6]} />
          <pointLight intensity={0.9} position={[0, 0, 6]} color={'white'} />
          <pointLight intensity={0.9} position={[0, 0, 0]} color={'white'} />
          <ambientLight intensity={0.4} />
        </group>
        <group ref={mapGroup}>
          <mesh
            position={[0, 0, 0]}
            rotation={[0, 0, 0]}
            scale={[1, 1, 1]}
        >
          <GracefulDegradation fps={24} />
          <SceneResizer dpr={0.6} />
          <FPSLimiter fps={24} />
          <sphereBufferGeometry args={[3, 64, 64]} />
            <meshPhysicalMaterial
              color={'#fff'}
              ref={material}
            metalness={0.99}
              reflectivity={0.99}
              needsUpdate={true}
              roughness={0.0}
              side={BackSide}
            />
          </mesh>
          <MapContent {...props} controls={controls} camera={camera} />
        </group>
        <OrbitControls enablePan={false} enableZoom={false} ref={controls} camera={camera.current} />
      </group>
  );
}

export default MapScene;
