import { useEffect } from 'react';

const Preview = (props) => {

  const { setPreviewItem, work, map, outsideOfHouse, homepage, garden, setGarden, setIs3D, setMapIsActive, setRooms, rooms, setPages2DOnly, pages2DOnly, is3D, isPreviewStyleNav, setIsPreviewStyleNav } = props;

  useEffect(() => {
    let prevEvent;
    const handleReceiveMessage = (event) => {
      const { data } = event;
      if (data) {
        const { collection, entry } = data;
        if (collection === 'gardenItems') {
          setPreviewItem({ ...entry, collection: 'gardenItems' });
          setGarden({ ...entry });
          setIs3D(true);
        }
        if (collection === 'rooms') {
          setPreviewItem({ ...entry, collection: 'rooms' });

          if (prevEvent) {

            const prevEntry = prevEvent.data.entry;
            if (prevEntry) {
              if (
                entry.mapPositionX !== prevEntry.mapPositionX ||
                entry.mapPositionY !== prevEntry.mapPositionY ||
                entry.mapPositionZ !== prevEntry.mapPositionZ ||
                entry.mapWidth !== prevEntry.mapWidth ||
                entry.mapHeight !== prevEntry.mapHeight ||
                entry.mapDepth !== prevEntry.mapDepth ||
                entry.mapImage?.path !== prevEntry.mapImage?.path ||
                entry.mapShape !== prevEntry.mapShape
              ) {
                setMapIsActive(true);
              } else {
                setMapIsActive(false);
              }
            }

            const updatedCollection = [...rooms];

            for (let i = 0; i < updatedCollection.length; i++) {
              let item = updatedCollection[i];
              if (item._id === entry._id) {
                updatedCollection[i] = {
                  ...entry,
                  collection: 'rooms',
                  content: {...entry.content}
                };
              }
            }

            setRooms(updatedCollection);
          }
        }

        if (collection === 'pages2DOnly') {
          setIs3D(false);
          setPreviewItem({
            ...entry,
            collection: 'pages2DOnly',
            content: {...entry.content}
          });

          if (prevEvent) {

            const updatedCollection = [...pages2DOnly];

            for (let i = 0; i < updatedCollection.length; i++) {
              let item = updatedCollection[i];
              if (item._id === entry._id) {
                updatedCollection[i] = {
                  ...entry,
                  collection: 'pages2DOnly',
                  content: {...entry.content}
                };
              }
            }

            setPages2DOnly(updatedCollection);

          }
        }
      }
      prevEvent = event;
    }

    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage, false);
    }
  }, [setPreviewItem, work, map, outsideOfHouse, homepage, garden, setGarden, setIs3D, setRooms, setMapIsActive, rooms, pages2DOnly, setPages2DOnly]);

  const toggleNavigationModeIn3D = () => {
    setIsPreviewStyleNav(!isPreviewStyleNav);
  }

  if (is3D === true) {
    return (
      <button
        onClick={toggleNavigationModeIn3D}
        className="button--nav-toggle--preview"
      >switch to {isPreviewStyleNav === true ? 'point and click' : 'orbit controls'} navigation</button>
    );
  } else {
    return null;
  }
}

export default Preview;