import PageItemSectionItem from './PageItemSectionItem';

const PageItemItemInGrid = (props) => {

  const { item, setIs3D } = props;

  return (
    <PageItemSectionItem {...props} item={item} setIs3D={setIs3D} />
  )
}

export default PageItemItemInGrid;