import { Fragment } from 'react';
import PageItemSectionItem from './PageItemSectionItem';

const PageItemSectionWrapper = (props) => {

  const { item, children, isDirectlyInsideColumn } = props;

  if (isDirectlyInsideColumn === true && item.component !== 'section' && item.component !== 'grid') {
    return (
      children
    );
  } else if (item.component === 'section' && isDirectlyInsideColumn !== true) {
    return (
      children
    )
  } else if (isDirectlyInsideColumn !== true && item.component === 'grid') {
    return (
      <div className="row">
        {children}
      </div>
    )
  } else if (isDirectlyInsideColumn === true && item.component === 'grid') {
    return (
      <div className="row">
        {children}
      </div>
    );
  } else if (isDirectlyInsideColumn === true && item.component === 'section') {
    return (
      <div className="row">
        {children}
      </div>
      );
  } else {
    return (
      children
    )
  }
}

const PageItemSection = (props) => {

  const { section, setIs3D } = props;

  return (
    <PageItemSectionWrapper {...props} item={section} isDirectlyInsideColumn={props.isDirectlyInsideColumn}>
      <Fragment>
      {
        section.children ?
          section.children[0] &&
          section.children.map(
            (item, i) => (
              item.component === 'grid' ?
                <PageItemSectionItem {...props} setIs3D={setIs3D} item={item} key={i} section={section} />
                :
                <div className="col-xxs-12 page__item__content__section" key={i}>
                  <PageItemSectionItem {...props} setIs3D={setIs3D} item={item} section={section} />
                </div>
            )
          )
          :
          <div className="row page__item__content__section">
            {
              section.component !== 'grid' ?
                <div className="col-xxs-12">
                  <PageItemSectionItem {...props} setIs3D={setIs3D} item={section} />
                </div>
              :
              <PageItemSectionItem {...props} setIs3D={setIs3D} item={section} />
            }
          </div>
        }
      </Fragment>
    </PageItemSectionWrapper>
  )
}

export default PageItemSection;