import { motion } from 'framer-motion';
import LazyloadImage from '../../../LazyloadImage';
import VideoPlayer from '../../../VideoPlayer';

const getMouseOrTouchPosition = (event) => {
  const coordinates = { x: -1, y: -1 };
  if (event.clientX && event.clientY) {
    coordinates.x = event.clientX;
    coordinates.y = event.clientY;
  } else if (event.touches && event.touches[0]) {
    coordinates.x = event.touches[0].clientX;
    coordinates.y = event.touches[0].clientY;
  }

  return coordinates;
}

const PageSlideshowViewSlide = (props) => {

  const { slide, handleClickNextSlide, handleClickPrevSlide, windowDimensions, infoIsActive } = props;
  const { windowHeight, windowWidth } = windowDimensions;

  const handleClickSlide = (e) => {
    const x = getMouseOrTouchPosition(e).x;
    if (slide.field.name === 'imageSlide') {
      if (x > windowWidth * 0.3) {
        handleClickNextSlide();
      } else {
        handleClickPrevSlide();
      }
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page__slideshow__slide"
      style={{ height: windowHeight + 'px' }}
    >
      <div
        className={`page__slideshow__slide__content__wrapper${slide.value.fullscreen === true ? ' cover' : ' contain'}`}
        onClick={handleClickSlide}
      >
      {
        slide.field.name === 'imageSlide' && slide.value.image?.path &&
        <LazyloadImage image={slide.value.image} fullscreen={slide.value.fullscreen === true} {...props} />
        }
      {
        slide.field.name === 'videoSlide' && slide.value.video &&
        <VideoPlayer
          url={slide.value.video}
          fullscreen={slide.value.fullscreen === true}
          contain={slide.value.fullscreen === false}
          infoIsActive={infoIsActive}
          {...props}
        />
      }
      {
        slide.field.name === 'vimeoSlide' && slide.value.vimeo &&
        <VideoPlayer
          url={slide.value.vimeo}
          fullscreen={slide.value.fullscreen === true}
          contain={slide.value.fullscreen !== true}
          infoIsActive={infoIsActive}
          {...props}
        />
      }
      </div>
    </motion.div>
  )
}

export default PageSlideshowViewSlide;