import { useRef, useState, useEffect, Suspense } from 'react';
import { cockpitUrl, getImage } from '../../../../utils/cockpit';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';

const VideoImageTexture = (props) => {

  const { imagePath } = props;
  const texture = useTexture(imagePath);

  if (texture.uuid) {
    return (
      <primitive
        attach="map"
        object={texture}
        format={THREE.RGBFormat}
        encoding={THREE.sRGBEncoding}
        mapping={THREE.EquirectangularRefractionMapping}
        anisotropy={4}
      />
    );
  } else {
    return null;
  }
}

const Video = (props) => {

  const { item, isFocused, depth, setDepth, width, setWidth, height, positionX, positionY, positionZ, rotation, setHeight, scaleMultiplier, handleItemClick, sceneIsLoaded } = props;
  const { value } = item;
  const { image, video, scale } = value;
  const [imagePath, setImagePath] = useState('');

  const [isPlaying, setIsPlaying] = useState(false);
  const [cannotAutoplay, setCannotAutoplay] = useState(false);

  const material = useRef();
  const [videoElement, setVideoElement] = useState({});

  useEffect(() => {

    let isMounted = true;

    let vid = document.createElement("video");
    const handlePlay = () => {
      if (vid.paused === false && isMounted === true) {
        setIsPlaying(true);
      }
      vid.removeEventListener('play', handlePlay);
    }

    if (video) {
      vid.src = cockpitUrl + '/' + video;
      vid.removeEventListener('play', handlePlay, false);
      vid.addEventListener('play', handlePlay, false);
      vid.playsInline = true;
      vid.crossOrigin = "Anonymous";
      vid.loop = true;
      vid.load();
      vid.preload = true;
      if (image && image.path) {
        vid.poster = cockpitUrl + image.path;
      }
      vid.muted = true;
      vid.play()
        .then(() => handlePlay())
        .catch(() => {
          if (isMounted === true) {
            setCannotAutoplay(true);
          }
        }
      );
      if (isMounted === true) {
        setVideoElement(vid);
      }
    }

    return () => {
      vid.pause();
      vid.removeEventListener('play', handlePlay);
      vid = null;
      isMounted = false;
    }
  }, [video, image]);

  const videoTexture = useRef();

  useEffect(() => {
    let isMounted = true;
    if (videoElement && isMounted === true && typeof videoElement.play !== 'undefined') {
      videoElement.play().catch(() => {
        if (isMounted === true) {
          setCannotAutoplay(true);
        }
      });
    }

    return () => {
      isMounted = false;
    }
  }, [videoElement]);

  useEffect(() => {
    let isMounted = true;
    const img = document.createElement('img');
    const handleImageLoad = () => {
      if (isMounted === true) {
        setWidth(parseFloat(scale) * scaleMultiplier);
        setHeight(
          (parseFloat(scale) * scaleMultiplier) / img.naturalWidth * img.naturalHeight
        );
        setDepth(1);
      }
    }
    if (image?.path) {
      const handleGetImage = (src) => {
        img.addEventListener('load', handleImageLoad);
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(image.path, 2048, 2048, 30, handleGetImage);
    }

    return () => {
      img.removeEventListener('load', handleImageLoad);
      isMounted = false;
    }
  }, [image, width, scale, scaleMultiplier, setWidth, setHeight, setDepth]);

  return (
    <Suspense fallback={null}>
      <group
        position={[
          positionX,
          positionY,
          positionZ
        ]}
        rotation={[0, rotation, 0]}
      >
          {
            imagePath !== '' && sceneIsLoaded === true &&
            <A11y role="button" description={`Click to view the work!`}>
              <mesh
                onPointerUp={(e) => {
                  if (cannotAutoplay === true) {
                    videoElement.play().catch(error => console.error(error));
                    videoElement.muted = false;
                  }
                  handleItemClick(e);
                }}
                onClick={(e) => {
                  if (cannotAutoplay === true) {
                    videoElement.play().catch(error => console.error(error));
                    videoElement.muted = false;
                  }
                  handleItemClick(e);
                }}
                onPointerOver={() => {
                  props.setHoveredItem({...props.focusedItemInfo})
                }}
                onPointerOut={() => {
                  props.setHoveredItem({})
                }}
              >
                <boxBufferGeometry args={[width, height, depth]} />
                <meshBasicMaterial attachArray="material" />
                <meshBasicMaterial attachArray="material" />
                <meshBasicMaterial attachArray="material" />
                <meshBasicMaterial attachArray="material" />
                <meshBasicMaterial refractionRatio={0.9} needsUpdate={true} ref={material} attachArray="material">
                  {
                    isPlaying === true && navigator.userAgent.toLowerCase().indexOf("android") === -1 ?
                    <videoTexture
                      ref={videoTexture}
                      attach="map"
                      args={[videoElement]}
                      anisotropy={isFocused === true ? 4 : 2}
                      format={THREE.RGBFormat}
                      encoding={THREE.sRGBEncoding}
                    />
                    :
                    <VideoImageTexture imagePath={imagePath} isFocused={isFocused} />
                  }
                </meshBasicMaterial>
                <meshBasicMaterial attachArray="material" />
              </mesh>
            </A11y>
          }
      </group>
    </Suspense>
  );
}

export default Video;
export { VideoImageTexture };