import { Switch, Route } from 'react-router-dom';
import LandingPage from './views/2D/LandingPage';
import Page from './components/2D/Page/Page';

const Main2D = (props) => {

  const { rooms, previewItem } = props;

  return (
    <div className="main--2d">
      <Switch>
        {
          !previewItem._id &&
          <Route
            exact
            path="/"
            render={() => (
              <LandingPage
                {...props}
              />
            )}
          />
        }
        {
          rooms &&
          rooms[0] &&
          rooms.map(
            (item, i) => (
              item.title_slug && item.title_slug !== '' &&
              <Route
                exact
                path={`/room/${item.title_slug}`}
                key={i}
                render={() => (
                  <Page item={item} {...props} />
                )}
              />
            )
          )
        }
        {
          rooms &&
          rooms[0] &&
          rooms.map(
            (item, i) => (
              <Route
                exact
                path={`/room/${item._id}`}
                key={i}
                render={() => (
                  <Page item={item} {...props} />
                )}
              />
            )
          )
        }
        {
          previewItem._id &&  previewItem.collection !== 'pages2DOnly' &&
          <Page item={previewItem} {...props} />
        }
      </Switch>
    </div>
  );
}

export default Main2D;
