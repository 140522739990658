import { useState, useEffect, useCallback } from 'react';
import PageSlideshowViewSlide from './PageSlideshowViewSlide';

const PageLayoutGridView = (props) => {

  const { windowDimensions, item } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const { content2DSlideshow } = item;
  const [activeSlide, setActiveSlide] = useState(0);
  const [infoIsActive, setInfoIsActive] = useState(false);

  const handleClickNextSlide = useCallback(() => {
    if (content2DSlideshow) {
      if (content2DSlideshow.length - 1 > activeSlide) {
        setActiveSlide(activeSlide + 1);
      } else {
        setActiveSlide(0);
      }
    }
  }, [content2DSlideshow, activeSlide]);

  const handleClickPrevSlide = useCallback(() => {
    if (content2DSlideshow) {
      if (0 < activeSlide) {
        setActiveSlide(activeSlide + 1);
      } else {
        setActiveSlide(content2DSlideshow.length - 1);
      }
    }
  }, [content2DSlideshow, activeSlide]);

  useEffect(() => {

    const handleKeyDown = (e) => {
      if (e.keyCode === 39) {
        handleClickNextSlide();
      } else if (e.keyCode === 37) {
        handleClickPrevSlide();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }

  }, [handleClickPrevSlide, handleClickNextSlide]);

  const handleClickInfoButton = () => {
    setInfoIsActive(!infoIsActive);
  }

  return (
    <div
      className="page__slideshow"
      style={{
        height: windowHeight + 'px',
        width: windowWidth + 'px'
      }}
    >
      <nav className="page__slideshow__controls">
        <button
          className="button--plain page__slideshow__button--direction page__slideshow__button--direction--prev"
          aria-label="view previous slide"
          onClick={handleClickPrevSlide}
        >prev</button>/
        <button
          className="button--plain page__slideshow__button--direction page__slideshow__button--direction--next"
          aria-label="view next slide"
          onClick={handleClickNextSlide}
        >next</button>
        {
          infoIsActive === true && content2DSlideshow[activeSlide] &&
          content2DSlideshow[activeSlide].value?.info && content2DSlideshow[activeSlide].value?.info !== '' &&
          <button
            className="button--plain page__slideshow__button--info"
            aria-label={`${infoIsActive === false ? 'show' : 'hide'} info`}
            onClick={handleClickInfoButton}
          >{infoIsActive === false ? 'info' : 'back'}</button>
        }
      </nav>
      {
        infoIsActive === true && content2DSlideshow[activeSlide] &&
        content2DSlideshow[activeSlide].value?.info && content2DSlideshow[activeSlide].value?.info !== '' &&
        <p className="page__slideshow__info">
            {content2DSlideshow[activeSlide].value.info}
        </p>
      }
      {
        content2DSlideshow &&
        content2DSlideshow[0] &&
        content2DSlideshow.map(
          (slide, i) => (
            i === activeSlide &&
            <PageSlideshowViewSlide
              key={i}
              {...props}
              slide={slide}
              handleClickNextSlide={handleClickNextSlide}
              handleClickPrevSlide={handleClickPrevSlide}
              infoIsActive={infoIsActive}
            />
          )
        )
      }
    </div>
  )
}

export default PageLayoutGridView;