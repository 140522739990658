import { useState, useEffect} from 'react';
import LandingPageContent from '../../components/2D/LandingPage/LandingPageContent';
import { getImage } from '../../utils/cockpit';

const LandingPage2D = (props) => {

  const { windowDimensions, homepage, setIs3D } = props;
  const { contentLayout, backgroundType, backgroundImage, backgroundColor} = homepage;
  const { windowWidth, windowHeight } = windowDimensions;
  const [backgroundImageSrc, setBackgroundImageSrc] = useState('');

  useEffect(() => {
    if (backgroundType === 'image' || backgroundType === 'repeating pattern') {
      if (backgroundImage && backgroundImage.path) {
        getImage(backgroundImage.path, 480, 480, 100, setBackgroundImageSrc);
      }
    }
  }, [ backgroundType, backgroundImage, backgroundColor ]);

  return (
    <div
      style={{
        height: windowHeight + 'px',
      }}
    >
      <div
        className="landing-page--2d__background pixelated"
        style={{
          height: windowHeight + 'px',
          width: windowWidth + 'px',
          filter: 'saturate(120%)',
          backgroundImage: backgroundImageSrc !== '' ? `url(${backgroundImageSrc})` : null,
          backgroundSize: backgroundType === 'image' ? 'cover' : null,
          backgroundPosition: backgroundType === 'image' ? 'center' : null
        }}
      />
      <div
        className="main--2d__inner landing-page--2d__content__wrapper"
        style={{
          height: windowHeight + 'px'
        }}
      >
        {
          contentLayout &&
          <LandingPageContent item={homepage} windowDimensions={windowDimensions} setIs3D={setIs3D} />
        }
      </div>
    </div>
  )
}

export default LandingPage2D;