import { useEffect } from 'react';
import { getImage } from '../../../utils/cockpit';

const CustomCursor = (props) => {

  const { cursorPath, setCursorSrc, setCursorCoords, customCursorClickPositionX, customCursorClickPositionY } = props;

  useEffect(() => {
    const img = document.createElement('img');

    const getCursorCoords = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      let x = 0, y = 0;

      if (customCursorClickPositionX && parseFloat(customCursorClickPositionX) > 0) {
        x = Math.floor(width / 100 * parseFloat(customCursorClickPositionX));
      }

      if (customCursorClickPositionY && parseFloat(customCursorClickPositionY) > 0) {
        y = Math.floor(height / 100 * parseFloat(customCursorClickPositionY));
      }

      setCursorCoords({ x: x, y: y });
    }

    const getCursorSrc = (src) => {
      setCursorSrc(src);
      img.addEventListener('load', getCursorCoords);
      img.src = src;
    }

    if (cursorPath) {
      getImage(cursorPath, 64, 64, 100, getCursorSrc);
    }

    return () => {
      img.removeEventListener('load', getCursorCoords);
    }
  }, [cursorPath, setCursorSrc, setCursorCoords, customCursorClickPositionX, customCursorClickPositionY]);

  return null;
}

export default CustomCursor;