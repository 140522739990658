import { useFrame } from '@react-three/fiber';
import { Sky, Stars } from '@react-three/drei';


const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

const SkyElement = (props) => {

  const { hours, setHours, isNight, setIsNight } = props;

  useFrame(() => {
    if (new Date().getHours() !== hours) {
      setHours(new Date().getHours());
      if (hours > 19 || hours < 7) {
        if (isNight === false) {
          setIsNight(true);
        }
      } else {
        if (isNight === true) {
          setIsNight(false);
        }
      }
    }
  });

  return (
    <group>
      <Sky
        distance={
          hours < 15 ? map(hours, 0, 15, 120000, 600) : map(hours, 24, 15, 120000, 600)
        }
        sunPosition={[
          hours < 15 ? map(hours, 0, 15, -50.0, 50.0) : map(hours, 24, 15, -50.0, 50.0),
          hours < 15 ? map(hours, 0, 15, -1.0, 4.0) : map(hours, 24, 15, -1.0, 4.0),
          0.0
        ]}
        turbidity={hours < 15 ? map(hours, 0, 15, 10, 2) : map(hours, 24, 15, 10, 2)}
        rayleigh={hours < 15 ? map(hours, 0, 15, 10, 1) : map(hours, 24, 15, 10, 1)}
        mieCoefficient={hours < 15 ? map(hours, 0, 15, 0.5, 0) : map(hours, 24, 15, 0.5, 0)}
        mieDirectionalG={hours < 15 ? map(hours, 0, 15, 1, 0) : map(hours, 24, 15, 0, 1)}
        {...props}
      />
      {
        isNight === true &&
        <Stars radius={200} depth={500} count={5000} factor={4} saturation={1}  />
      }
    </group>
  )
}

export default SkyElement;