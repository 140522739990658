import { useRef, useState, useEffect, Suspense } from 'react';
import FloorHoverNavSpace from '../../../utils/ThreeHelpers/FloorHoverNavSpace';
import RoomContentItem from './RoomContentItem';
import RoomWalls from './Walls/RoomWalls';
import RoomSkydome from './Walls/RoomSkydome';
import RoomSky from './Walls/RoomSky';
import EntryPositionAndLookAtPositionHelpers from '../../../utils/ThreeHelpers/EntryPositionAndLookAtPositionHelpers';

const Room = (props) => {

  const { room, setIs3D } = props;
  const { content, wallsType, entryPositionX, entryPositionZ, entryLookAtX, entryLookAtZ, defaultDimensionality } = room;
  const floorRef = useRef();
  const [roomHeight] = useState(170);
  const [target, setTarget] = useState([0, 10, 0]);
  const [position, setPosition] = useState([entryPositionX ? parseFloat(entryPositionX) : 0, 40, entryPositionZ ? parseFloat(entryPositionZ) : 0]);

  const [hasWalls, setHasWalls] = useState(false);
  const [hasCeiling, setHasCeiling] = useState(false);
  const [hasFloor, setHasFloor] = useState(false);

  useEffect(() => {
    if (defaultDimensionality === false) {
      setIs3D(false);
    }
  }, [defaultDimensionality, setIs3D]);

  useEffect(() => {
    let areThereWalls = false;
    let areThereCeiling = false;
    let areThereFloor = false;

    if (typeof room.walls === 'object' && room.walls !== null) {
      if (Object.prototype.toString.call(room.walls) !== '[object Array]') {
        if (typeof room.walls.path === 'string' && room.walls.path !== '') {
          areThereWalls = true;
        }
      }
    }

    if (typeof room.ceiling === 'object' && room.ceiling !== null) {
      if (Object.prototype.toString.call(room.ceiling) !== '[object Array]') {
        if (typeof room.ceiling.path === 'string' && room.ceiling.path !== '') {
          areThereCeiling = true;
        }
      }
    }

    if (typeof room.floor === 'object' && room.floor !== null) {
      if (Object.prototype.toString.call(room.floor) !== '[object Array]') {
        if (typeof room.floor.path === 'string' && room.floor.path !== '') {
          areThereFloor = true;
        }
      }
    }

    setHasWalls(areThereWalls);
    setHasCeiling(areThereCeiling);
    setHasFloor(areThereFloor);
  }, [room.walls, room.ceiling, room.floor]);

  useEffect(() => {
    setPosition([entryPositionX ? parseFloat(entryPositionX) : 0, 40, entryPositionZ ? parseFloat(entryPositionZ) : 0]);
    setTarget([entryLookAtX ? parseFloat(entryLookAtX) : 0, 10, entryLookAtZ ? parseFloat(entryLookAtZ) : 0]);
  }, [entryPositionX, entryPositionZ, entryLookAtX, entryLookAtZ]);

  if (room.width && room.depth) {
    return (
      <Suspense fallback={null}>
        <FloorHoverNavSpace
          floorRef={floorRef}
          floorWidth={parseFloat(room.width)}
          floorDepth={parseFloat(room.depth)}
          position={position}
          target={target}
          {...props}
          isGarden={false}
        >
          <EntryPositionAndLookAtPositionHelpers {...props} {...room} />
        {
          (wallsType === 'walls and ceiling' || !wallsType) &&
            <RoomWalls
            {...props}
            width={parseFloat(room.width)}
            height={roomHeight}
            depth={parseFloat(room.depth)}
            walls={hasWalls === true ? room.walls : {}}
            floor={hasFloor === true ? room.floor : {}}
            ceiling={hasCeiling === true ? room.ceiling : {}}
            wallsRepeat={room.wallsRepeat ? parseFloat(room.wallsRepeat) : 1}
            ceilingRepeat={room.ceilingRepeat ? parseFloat(room.ceilingRepeat) : 1}
            floorRepeat={room.floorRepeat ? parseFloat(room.floorRepeat) : 1}
            room={room}
          />
        }
        {
          wallsType === 'skydome' &&
          <RoomSkydome
            {...props}
            width={parseFloat(room.width)}
            height={roomHeight}
            depth={parseFloat(room.depth)}
            walls={hasWalls === true ? room.walls : {}}
            floor={hasFloor === true ? room.floor : {}}
            ceiling={hasCeiling === true ? room.ceiling : {}}
            floorRepeat={parseFloat(room.floorRepeat)}
            room={room}
          />
          }
          {
            wallsType === 'sky' &&
            <RoomSky
              {...props}
              width={parseFloat(room.width)}
              height={roomHeight}
              depth={parseFloat(room.depth)}
              floor={room.floor && typeof room.floor?.path !== 'undefined' ? room.floor : {}}
              floorRepeat={parseFloat(room.floorRepeat)}
              room={room}
            />
          }
          <group position={[0, 60, 0]}>
            {
              content &&
              content[0] &&
              content.map(
                (item, i) => (
                  <RoomContentItem
                    {...props}
                    item={item}
                    key={i}
                    itemIndex={i}
                    type={'area item'}
                    roomHeight={roomHeight}
                  />
                )
              )
            }
          </group>
          <ambientLight
            intensity={room.mainLightBrightness ? parseFloat(room.mainLightBrightness) / 100 : 1}
            color={room.mainLightColor ? room.mainLightColor : 0xffffff}
          />
        </FloorHoverNavSpace>
      </Suspense>
    );
  } else {
    return null;
  }
}

export default Room;