import { useRef, useState, useEffect } from 'react';
import { DoubleSide } from 'three';
import { useFrame } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import { getImage } from '../../utils/cockpit';
import blobby from '../../../assets/textures/alphaMaps/blobbyRect.png';
import circle from '../../../assets/textures/alphaMaps/circle.png';
import weird from '../../../assets/textures/alphaMaps/weird2.jpg';
import shatter from '../../../assets/textures/alphaMaps/shatter.png';
import smoke from '../../../assets/textures/alphaMaps/smoke.png';

const MapRoomAlpha = (props) => {

  const { mapShape } = props;

  const blobbyAlpha = useTexture(blobby);
  const circleAlpha = useTexture(circle);
  const weirdAlpha = useTexture(weird);
  const shatterAlpha = useTexture(shatter);
  const smokeAlpha = useTexture(smoke);

  if (blobbyAlpha.uuid && mapShape === 'blobby') {
    return (
      <primitive object={blobbyAlpha} attach="alphaMap" />
    );
  } else if (circleAlpha.uuid && mapShape === 'circle') {
    return (
      <primitive object={circleAlpha} attach="alphaMap" />
    );
  } else if (weirdAlpha.uuid && mapShape === 'weird') {
    return (
      <primitive object={weirdAlpha} attach="alphaMap" />
    );
  } else if (shatterAlpha.uuid && mapShape === 'shatter') {
    return (
      <primitive object={shatterAlpha} attach="alphaMap" />
    );
  } else if (smokeAlpha.uuid && mapShape === 'smoke') {
    return (
      <primitive object={smokeAlpha} attach="alphaMap" />
    );
  } else {
    return null;
  }
}

const MapRoomTexture = (props) => {

  const { mapImage } = props;
  const texture = useTexture(mapImage);

  if (texture.uuid) {
    return (
      <primitive object={texture} attach="map" />
    );
  } else {
    return null;
  }
}

const MapRoom = (props) => {

  const { item, camera, index, hoveredMapItem, setHoveredMapItem, setMapIsActive } = props;
  const { mapPositionX, mapPositionY, mapPositionZ, mapWidth, mapHeight, mapDepth } = item;
  const [roomLink, setRoomLink] = useState('');
  const [itemDimensions, setItemDimensions] = useState([0.5, 0.5]);
  const [itemPosition, setItemPosition] = useState([50, 50, 50]);
  const [mapImage, setMapImage] = useState('');

  const mesh = useRef();

  useEffect(() => {
    setRoomLink(`/room/${item.title_slug && item.title_slug !== '' ? item.title_slug : item._id}`);
  }, [item.title_slug, item._id]);

  useEffect(() => {
    if (item.mapImage?.path !== '') {
      getImage(item.mapImage.path, 240, 240, 100, setMapImage);
    }
  }, [item.mapImage]);

  useEffect(() => {
    if (mesh.current) {
      setItemDimensions([parseFloat(mapWidth), parseFloat(mapHeight)]);
      setItemPosition([
        mapPositionX ? mapPositionX - 50 : -50,
        mapPositionY ? mapPositionY - 50 : -50,
        mapPositionZ ? mapPositionZ - 50 : -50
      ]);
    }
  }, [mapPositionX, mapPositionY, mapPositionZ, mapWidth, mapHeight, mapDepth]);

  useFrame(() => {
    if (camera.current && mesh.current) {
      mesh.current.rotation.x = camera.current.rotation.x;
      mesh.current.rotation.y = camera.current.rotation.y;
      mesh.current.rotation.z = camera.current.rotation.z;
    }
  });

    return (
      <group position={itemPosition} lookAt={camera.current?.position ? camera.current.position : null}>
        <A11y role="button" description={`Visit ${item.title}`}>
          {
            mapImage === '' ?
              <mesh
                ref={mesh}
                onPointerOver={() => {
                  setHoveredMapItem({
                    title: item.title,
                    _id: item._id,
                    link: roomLink,
                    index: index
                  });
                }}
                onPointerDown={() => {
                  if (props.userIsTouching === true) {
                    props.history.push(roomLink);
                  }
                }}
                onPointerLeave={() => {
                  if (hoveredMapItem.index === index) {
                    setHoveredMapItem({});
                  }
                }}
              >
                <sphereBufferGeometry args={[itemDimensions[0] * 0.08, 24]} />
                <meshPhongMaterial color="hotpink" />
              </mesh>
              :
              <mesh
                ref={mesh}
                onPointerOver={() => {
                  setHoveredMapItem({
                    title: item.title,
                    _id: item._id,
                    link: roomLink,
                    index: index
                  });
                }}
                onPointerDown={() => {
                  if (props.userIsTouching === true) {
                    setHoveredMapItem({
                      title: item.title,
                      _id: item._id,
                      link: roomLink,
                      index: index
                    });
                  }
                }}
                onPointerUp={() => {
                  if (props.userIsTouching === true && hoveredMapItem.index === index) {
                    props.history.push(roomLink);
                    setMapIsActive(false);
                  }
                }}
                onPointerLeave={() => {
                  if (hoveredMapItem.index === index && props.userIsTouching === false) {
                    setHoveredMapItem({});
                  }
                }}
              >
                <planeBufferGeometry args={itemDimensions} />
                <meshLambertMaterial
                  side={DoubleSide}
                  transparent={true}
                  needsUpdate={true}
                >
                    <MapRoomTexture mapImage={mapImage} />
                  {
                    item.mapShape && item.mapShape !== 'none' &&
                    <MapRoomAlpha mapShape={item.mapShape} />
                  }
                </meshLambertMaterial>
              </mesh>
          }
        </A11y>
        {
          (props.previewItem?._id === item._id || hoveredMapItem.index === index) &&
          <mesh>
            <sphereBufferGeometry args={[mapWidth / 2, 4, 4]} />
            <meshBasicMaterial wireframe={true} color={'hotpink'} />
          </mesh>
        }
      </group>
    )
}

const MapContent = (props) => {

  const { rooms } = props;

  return (
    <group>
      <group
        scale={[0.034, 0.034, 0.034]}
      >
        {
          rooms &&
          rooms[0] &&
          rooms.map(
            (item, i) => (
              item.mapImage?.path &&
              <MapRoom item={item} key={i} index={i} {...props} />
            )
          )
        }
      </group>
    </group>
  );
}

export default MapContent;
