import { useRef } from 'react';
import * as THREE from 'three';
import CustomWallTexture from './CustomWallTexture';
import RoomWallsEnvMap from './RoomWallsEnvMap';

const CustomCeilingMaterial = (props) => {

  const { width, depth, color } = props;
  const material = useRef();

  return (
    <meshPhysicalMaterial
      attachArray="material"
      color={color ? color : '#ffffff'}
      side={THREE.FrontSide}
      metalness={0.1}
      roughness={0.4}
      reflectivity={0.2}
      needsUpdate={true}
      ref={material}
    >
      <CustomWallTexture
        material={material}
        repeat={[width ? width : 600, depth ? depth : 600]}
        {...props}
      />
      <RoomWallsEnvMap material={material} />
    </meshPhysicalMaterial>
  )
};

export default CustomCeilingMaterial;