import { Suspense, useState, useEffect } from 'react';
import { A11yAnnouncer } from '@react-three/a11y';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import MapScene from './MapScene';
import MapRing from '../../../assets/UI/map-ring-4.svg';
import Map2D from './Map2D';

const Map = (props) => {

  const { mapIsActive, setMapIsActive, windowDimensions, is3D, siteOptions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [mapInstructionsAreActive, setMapInstructionsAreActive] = useState(true);
  const [hoveredMapItem, setHoveredMapItem] = useState({});
  const [texturesAreLoaded, setTexturesAreLoaded] = useState(false);

  useEffect(() => {
    if (mapIsActive === false || is3D === false) {
      setTexturesAreLoaded(false);
    }
  }, [mapIsActive, is3D]);

  if (mapIsActive === true) {
    return (
      <div
        className="map__wrapper"
        style={{
          width: Math.min(windowWidth, windowHeight) - 40 + 'px',
          height: Math.min(windowWidth, windowHeight) - 40 + 'px'
        }}
        onMouseDown={() => setMapInstructionsAreActive(false)}
        onClick={() => {
          if (hoveredMapItem.link && hoveredMapItem.link !== '') {
            props.history.push(hoveredMapItem.link);
            setMapIsActive(false);
            setHoveredMapItem({});
          }
        }}
        onTouchStart={() => setMapInstructionsAreActive(false)}
      >
        <div className="map__inner">
          <div className="map__ring__wrapper">
            <img className="map__ring" src={MapRing} alt="ring" />
          </div>
          {
            is3D === true ?
              <div
                className="map__canvas__wrapper"
                style={{
                  opacity: texturesAreLoaded === true ? 1 : 0,
                  pointerEvents: texturesAreLoaded === true ? null : 'none'
                }}
              >
                <Canvas
                  gl={{
                    antialias: false,
                    linear: true
                  }}
                  onCreated={({ gl }) => {
                    gl.toneMapping = THREE.NoToneMapping;
                    gl.toneMappingExposure = 1.25;
                    gl.linear = true;
                    gl.getContext('webgl').webkitImageSmoothingEnabled = false;
                    gl.getContext('webgl').imageSmoothingEnabled = false;
                  }}
                >
                  <Suspense fallback={null}>
                    <MapScene
                      hoveredMapItem={hoveredMapItem}
                      setHoveredMapItem={setHoveredMapItem}
                      setTexturesAreLoaded={setTexturesAreLoaded}
                      {...props}
                    />
                  </Suspense>
                </Canvas>
                <A11yAnnouncer />
              </div>
              :
              <Map2D {...props} />
          }
            {
              (mapInstructionsAreActive === true || hoveredMapItem.index) && is3D === true && texturesAreLoaded === true &&
              <div>
                <p className="map__instructions__text">
                  {
                    mapInstructionsAreActive === true ?
                      siteOptions.mapInstructionText && siteOptions.mapInstructionText !== '' ? siteOptions.mapInstructionText : 'Drag the map to rotate. Click an item to be transported to its room.'
                      :
                      hoveredMapItem.title
                  }
                </p>
              </div>
            }
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Map;
