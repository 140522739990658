import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState, useRef, Fragment } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';
import * as _ from 'underscore';
import { durationInSecondsGetHours, durationInSecondsGetMinutes, durationInSecondsGetSeconds } from '../utils/duration.js';

const VideoPlayerControls = (props) => {

  const { duration, isPlaying, currentTime, setIsPlaying, setCurrentTime, handleSeekThrottled } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: .2 }}
      className="video-player__controls__inner player__controls__inner"
    >
      <div className="video-player__time__wrapper player__time__wrapper">
        <p className="video-player__time player__time video-player__time--elapsed player__time--elapsed">
          {durationInSecondsGetHours(currentTime)} : {durationInSecondsGetMinutes(currentTime)} : {durationInSecondsGetSeconds(currentTime)}
        </p>
        <button
          className={`video-player__button--play-pause player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
          onClick={(e) => {
            typeof e !== 'undefined' && e.preventDefault();
            setIsPlaying(!isPlaying);
          }}
        />
        <div className="video-player__input--time__wrapper player__input--time__wrapper">
          <input
            type="range"
            className="video-player__input--time player__input--time"
            name="time"
            max={duration}
            value={currentTime}
            onChange={(e) => {
              setIsPlaying(false);
              setCurrentTime(e.target.value);
              handleSeekThrottled(e);
            }}
            onMouseDown={() => {
              setIsPlaying(false);
            }}
            onMouseUp={() => {
              setIsPlaying(true);
            }}
          />
        </div>
      </div>
    </motion.div>
  )
}

const VideoPlayer = (props) => {

  const { url, noControls, isLooping, fullscreen, contain, windowWidth, windowHeight, infoIsActive, handleClickNextSlide, handleClickPrevSlide, aspectRatio } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoUiIsHidden, setVideoUiIsHidden] = useState(false);
  const videoPlayerMiniInactivityTimeout = useRef();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoAspectRatio, setVideoAspectRatio] = useState(0.5625);
  const [isEmbed] = useState(url.indexOf('storage') === 0 || url.indexOf('/storage') === 0 ? false : true);
  const [isYoutube, setIsYoutube] = useState(false);
  const wrapper = useRef();

  useEffect(() => {
    if (aspectRatio) {
      setVideoAspectRatio(aspectRatio);
    }
  }, [aspectRatio]);

  useEffect(() => {
    if (wrapper.current) {
      const maxWidth = wrapper.current.parentNode.offsetWidth;
      const maxHeight = windowHeight;
      if (maxWidth * videoAspectRatio <= maxHeight) {
        wrapper.current.style.width = maxWidth + 'px';
        wrapper.current.style.height = maxWidth * videoAspectRatio + 'px';
      } else {
        wrapper.current.style.height = maxHeight + 'px';
        wrapper.current.style.width = maxHeight / videoAspectRatio + 'px';
      }
    }
  }, [videoAspectRatio, windowHeight]);

  const player = useRef();

  useEffect(() => {
    let isMounted = true;
    if (url) {
      if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
        if (isMounted === true) {
          setVideoUrl(`${cockpitUrl}/${url}`);
        }
      } else {
        if (isMounted === true) {
          let newUrl = url;
          if (url.indexOf('youtube.com') > -1 || url.indexOf('youtu.be') > -1) {
            setIsYoutube(true);
          }
          if (url.indexOf('youtube.com/shorts') > -1) {
            if (newUrl.indexOf('?' > -1)) {
              let index = newUrl.indexOf('?');
              if (index > -1) {
                newUrl = url.substring(0, index);
              } else {
                newUrl = url;
              }
            }
          }
          setVideoUrl(newUrl.replace('youtube.com/shorts', 'youtube.com/watch'));
        }
      }
    }
    return () => {
      isMounted = false;
    }
  }, [url]);

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  const getCurrentTimeThrottled = _.throttle(getCurrentTime, 120);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value);
  }

  const handleSeekThrottled = _.throttle(handleSeek, 120);

  const onInactivityTimeoutCompleted = () => {
    setVideoUiIsHidden(true);
  }

  const handleInteract = (e) => {
    clearTimeout(videoPlayerMiniInactivityTimeout.current);
    setVideoUiIsHidden(false);
    videoPlayerMiniInactivityTimeout.current = setTimeout(onInactivityTimeoutCompleted, 3000);
    if (noControls === true && e._reactName === 'onClick') {
      setIsPlaying(!isPlaying);
    }
  }

  const handleFullScreen = (e) => {
    typeof e !== 'undefined' && e.preventDefault();
    if (player.current?.getInternalPlayer()) {
      if (player.current?.getInternalPlayer().requestFullscreen) {
        player.current?.getInternalPlayer().requestFullscreen();
      } else if (player.current?.getInternalPlayer().webkitRequestFullscreen) { /* Safari */
        player.current?.getInternalPlayer().webkitRequestFullscreen();
      } else if (player.current?.getInternalPlayer().msRequestFullscreen) { /* IE11 */
        player.current?.getInternalPlayer().msRequestFullscreen();
      }
    }
  }

  useEffect(() => {
  }, [isEmbed, fullscreen, windowWidth, windowHeight]);

  if (url) {
    return (
      <Fragment>
        <div
          ref={wrapper}
          className={`video-player ${isEmbed === false ? 'video-player--video-element' : 'video-player--embed'}${fullscreen === true ? ' video-player--cover' : ''}${isYoutube === true ? ' video-player--youtube' : ''}`}
          onClick={(e) => {
            handleInteract(e);
            handleClickNextSlide && handleClickNextSlide(e)
            handleClickPrevSlide && handleClickPrevSlide(e);
          }}
          onTouchStart={handleInteract}
          onMouseMove={handleInteract}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <div className="video__wrapper">
            {
              1 === 2 &&
              <button
                className="button--fullscreen"
                aria-label="view fullscreen"
                onClick={handleFullScreen}
              ></button>
            }
            <ReactPlayer
              ref={player}
              url={videoUrl}
              className="video-player__player"
              playsinline={true}
              playing={isPlaying}
              volume={1}
              muted={false}
              height={'100%'}
              width={'100%'}
              loop={isLooping === true || isYoutube === true || isEmbed === true ? true : false}
              onCanPlay={(e) => {
                if (isEmbed === false) {
                  if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                    setVideoAspectRatio(player.current.getInternalPlayer().videoHeight / player.current.getInternalPlayer().videoWidth);
                  }
                }
              }}
              onPlay={() => { setIsPlaying(true) }}
              onPause={() => { setIsPlaying(false) }}
              onProgress={(e) => {
                getCurrentTimeThrottled(e)
              }}
              onSeek={(e) => {
                getCurrentTimeThrottled(e)
              }}
              onDuration={(e) => {
                getDuration(e)
              }}
            />
          </div>
          {
            fullscreen !== true && contain !== true &&
            <div className={`video-player__controls player__controls`}>
              <AnimatePresence>
                {
                  (videoUiIsHidden === false || isPlaying === false) && noControls !== true && isYoutube === false && isEmbed === false &&
                  <VideoPlayerControls
                    duration={duration}
                    isPlaying={isPlaying}
                    currentTime={currentTime}
                    setIsPlaying={setIsPlaying}
                    setCurrentTime={setCurrentTime}
                    handleSeekThrottled={handleSeekThrottled}
                  />
                }
              </AnimatePresence>
            </div>
          }
        </div>
        {
          (fullscreen === true || contain === true) && infoIsActive !== true &&
          <div className={`video-player__controls player__controls player__controls--separate`}>
            <AnimatePresence>
              {
                (videoUiIsHidden === false || isPlaying === false) && noControls !== true && 
                isYoutube === false && isEmbed === false &&
                <VideoPlayerControls
                  duration={duration}
                  isPlaying={isPlaying}
                  currentTime={currentTime}
                  setIsPlaying={setIsPlaying}
                  setCurrentTime={setCurrentTime}
                  handleSeekThrottled={handleSeekThrottled}
                />
              }
            </AnimatePresence>
          </div>
        }
      </Fragment>
    )
  } else {
    return null;
  }
}

export default VideoPlayer;