import { useRef, useState, useEffect } from 'react';
import { getImage } from '../../../../utils/cockpit';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';

const ImageTexture = (props) => {

  const { imagePath } = props;
  const texture = useTexture(imagePath);

  if (texture.uuid) {
    return (
      <primitive
        attach="map"
        object={texture}
        encoding={THREE.sRGBEncoding}
        mapping={THREE.EquirectangularRefractionMapping}
        anisotropy={4}
        needsUpdate={true}
      />
    );
  } else {
    return null;
  }
}

const Image = (props) => {

  const { item, isFocused, height, setHeight, isHovered, setIsHovered, width, setWidth, positionX, positionY, positionZ, rotation, scaleMultiplier, depth, setDepth, handleItemClick } = props;
  const { value } = item;
  const { image, scale, rotationX, rotationZ } = value;
  const [imagePath, setImagePath] = useState('');

  const material = useRef();

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        // material.current.opacity = 0.6;
      }
    } else {
      if (material.current) {
        // material.current.opacity = 1;
      }
    }
  }, [isHovered]);

  useEffect(() => {
    if (image?.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          setWidth(parseFloat(scale) * scaleMultiplier);
          setHeight(
            (parseFloat(scale) * scaleMultiplier) / img.naturalWidth * img.naturalHeight
          );
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(image.path, 2048, 2048, 30, handleGetImage);
    }
  }, [image, scale, setWidth, setHeight, scaleMultiplier, setDepth]);

  if (image && image.path) {
    return (
      <group
        position={[
          positionX,
          positionY,
          positionZ
        ]}
        rotation={[rotationX ? rotationX / 180 * Math.PI : 0, rotation ? rotation : 0, rotationZ ? rotationZ / 180 * Math.PI : 0]}
      >
        {
          imagePath !== '' && scale && height &&
          <A11y role="button" description={`Click to view the work!`}>
            <mesh
              onClick={handleItemClick}
              onPointerOver={() => {
                setIsHovered(true);
                props.setHoveredItem({ ...props.focusedItemInfo });
              }}
              onPointerOut={() => {
                setIsHovered(false);
                props.setHoveredItem({});
              }}
            >
                <boxBufferGeometry args={[width, height, depth]} name={item.value.title} />
                <meshPhongMaterial transparent={true} opacity={0} attachArray="material" />
                <meshPhongMaterial transparent={true} opacity={0} attachArray="material" />
                <meshPhongMaterial transparent={true} opacity={0} attachArray="material" />
                <meshPhongMaterial transparent={true} opacity={0} attachArray="material" />
                <meshBasicMaterial transparent={true} needsUpdate={true} ref={material} attachArray="material" side={THREE.DoubleSide}>
                {
                  imagePath && imagePath !== '' &&
                  <ImageTexture imagePath={imagePath} isFocused={isFocused} needsUpdate={true} />
                }
                </meshBasicMaterial>
                <meshPhongMaterial transparent={true} opacity={0} attachArray="material" />
            </mesh>
          </A11y>
        }
      </group>
    );
  } else {
    return null;
  }
}

export default Image;