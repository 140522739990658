const PageItemSectionButton3D = (props) => {

  const { setIs3D } = props;

  return (
    <div className="col-xxs-12">
      <button
        className="page__item__section__button--3d"
        onClick={() => {
          typeof setIs3D === 'function' && setIs3D(true);
        }}
      >View in 3D</button>
    </div>
  )
}

export default PageItemSectionButton3D;