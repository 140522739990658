import PageItemSection from './PageItemSection';
import PageItemItemInGrid from './PageItemItemInGrid';

const PageItemSectionGridColumn = (props) => {

  const { column, className, setIs3D } = props;

  return (
    <div className={className}>
      {
        column.children &&
        column.children[0] &&
        column.children.map(
          (item, i) => (
            item.component === 'section' ?
              <PageItemSection {...props} setIs3D={setIs3D} section={item} key={i} className="row" isDirectlyInsideColumn={true} />
              :
              <PageItemItemInGrid {...props} setIs3D={setIs3D} item={item} key={i} />
          )
        )
      }
    </div>
  )
}

export default PageItemSectionGridColumn;