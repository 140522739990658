import { motion } from 'framer-motion';
import VideoPlayer from '../../VideoPlayer';
import LazyloadImage from '../../LazyloadImage';
import WysiwygContent from '../../2D/WysiwygContent';

const Overlay = (props) => {

  const { overlayContent, setOverlayContent, windowDimensions } = props;
  const { windowHeight } = windowDimensions;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
      className={`overlay--3D ${overlayContent.type === 'video' || overlayContent.type === 'videoEmbed' ? 'overlay--3D--video' : ''}${overlayContent.type === 'text' ? 'overlay--3D--text' : ''}${overlayContent.type === 'image' ? 'overlay--3D--image' : ''}`}
    >
      <div className="overlay--3D__content__wrapper contain">
        {
          overlayContent.type === 'video' && overlayContent.value &&
          <VideoPlayer url={overlayContent.value} {...props} contain={true} />
        }
        {
          overlayContent.type === 'videoEmbed' && overlayContent.value &&
          <VideoPlayer url={overlayContent.value} {...props} contain={true} />
        }
        {
          overlayContent.type === 'image' && overlayContent.value &&
          <LazyloadImage image={overlayContent.value} {...props} />
        }
        {
          overlayContent.type === 'text' && overlayContent.value && overlayContent.value !== '' &&
          <div className="overlay--3D__text">
            <WysiwygContent {...props} content={overlayContent.value} />
          </div>
        }
      </div>
      <button className="button--plain overlay--3D__button--close" onClick={() => { setOverlayContent({}); }}>
        <div className="overlay--3D__button--close__inner">
          close
        </div>
      </button>
    </motion.div>
  )
}

export default Overlay;