import { getImage } from '../../../../utils/cockpit';
import { useEffect, useState } from 'react';
import CustomFloorMaterial from './CustomFloorMaterial'
import PlainMaterial from './PlainMaterial';
import SkyElement from '../../Garden/SkyElement';

const RoomSky = (props) => {

  const { width, height, depth, walls, floor, floorRepeat, room } = props;

  const [floorImagePath, setFloorImagePath] = useState('');
  const [hours, setHours] = useState(new Date().getHours());
  const [isNight, setIsNight] = useState(hours > 19 || hours < 7 ? true : false);

  useEffect(() => {
    if (floor && floor.path) {
      getImage(floor.path, 2048, 2048, 60, setFloorImagePath);
    }
  }, [walls, floor]);

  return (
    <group>

      <SkyElement {...props} hours={hours} setHours={setHours} isNight={isNight} setIsNight={setIsNight} />
      <mesh position={[0, 70, 0]}>
        <boxBufferGeometry args={[-width * 4, height, depth * 4]} name="room walls" />
        {/* left wall */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        {/* right wall */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        {
          floorImagePath !== '' ?
            <CustomFloorMaterial width={depth * 4 / 1000 * floorRepeat} depth={width * 4 / 1000 * floorRepeat} textureUrl={floorImagePath} />
          :
          <PlainMaterial color={room.floorColor} />
        }
        {/* front wall (furthest from camera) */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        {/* back wall (nearest to camera) */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
      </mesh>
    </group>
  )
}

export default RoomSky;