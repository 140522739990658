import PageItemSectionImage from './PageItemSectionImage';

const PageItemSectionGallery = (props) => {

  const { item } = props;

  if (item.settings.gallery && item.settings.gallery[0]) {
    return (
      <div className="gallery row">
        {
          item.settings.gallery.map(
            (image, i) => (
              <div className={`gallery__item col-xxs-12 ${item.settings.gallery.length >= 3 ? 'col-xs-6 col-sm-4' : item.settings.gallery.length === 2 ? 'col-xs-6' : ''}`} key={i}>
                <PageItemSectionImage {...props} item={image} />
              </div>
            )
          )
        }
      </div>
    )
  } else {
    return null;
  }
}

export default PageItemSectionGallery;