import { A11y } from '@react-three/a11y';
import { FloorMesh } from '../../components/3D/Floor';

const Floor = (props) => {

  const { floorChildren } = props;

  return (
    <A11y role="button" description="Click a point on the floor to travel there!">
      <group>
        <FloorMesh {...props}>
          <meshLambertMaterial needsUpdate={true} transparent={true} opacity={0} wireframe={true} color={'hotpink'}>
          </meshLambertMaterial>
        </FloorMesh>
        {floorChildren && floorChildren}
      </group>
    </A11y>
  )
}

export default Floor;