import { getImage } from '../../../../utils/cockpit';
import { useEffect, useState } from 'react';
import CustomWallMaterial from './CustomWallMaterial';
import CustomCeilingMaterial from './CustomCeilingMaterial';
import CustomFloorMaterial from './CustomFloorMaterial';
import PlainMaterial from './PlainMaterial';

const RoomWalls = (props) => {

  const { width, height, depth, walls, floor, ceiling, wallsRepeat, ceilingRepeat, floorRepeat, room, sceneIsLoaded } = props;

  const [wallsImagePath, setWallsImagePath] = useState('');
  const [ceilingImagePath, setCeilingImagePath] = useState('');
  const [floorImagePath, setFloorImagePath] = useState('');

  useEffect(() => {

    if (walls) {
      if (walls.path && walls.path !== '') {
        getImage(walls.path, 1024, 1024, 60, setWallsImagePath);
      }
    }
    if (ceiling) {
      if (ceiling.path && ceiling.path !== '') {
        getImage(ceiling.path, 1024, 1024, 60, setCeilingImagePath);
      }
    }
    if (floor) {
      if (floor.path && floor.path !== '') {
        getImage(floor.path, 1024, 1024, 60, setFloorImagePath);
      }
    }
  }, [walls, ceiling, floor]);

  return (
      <mesh position={[0, 70, 0]}>
        <boxBufferGeometry args={[-width, height, depth]} name="room walls" />
        {/* left wall */}
        {
          wallsImagePath !== '' && sceneIsLoaded === true ?
          <CustomWallMaterial width={depth / 1000 * wallsRepeat} height={height / 1000 * wallsRepeat} textureUrl={wallsImagePath} />
            :
          <PlainMaterial color={room.wallsColor} />
        }
        {/* right wall */}
        {
          wallsImagePath !== '' && sceneIsLoaded === true ?
            <CustomWallMaterial width={depth / 1000 * wallsRepeat} height={height / 1000 * wallsRepeat} textureUrl={wallsImagePath} />
          :
          <PlainMaterial color={room.wallsColor} />
        }
        {
          ceilingImagePath !== '' && sceneIsLoaded === true ?
          <CustomCeilingMaterial width={width / 1000 * ceilingRepeat} depth={depth / 1000 * ceilingRepeat} textureUrl={ceilingImagePath} />
          :
          <PlainMaterial color={room.ceilingColor} />
        }
        {
          floorImagePath !== '' && sceneIsLoaded === true ?
            <CustomFloorMaterial width={width / 1000 * floorRepeat} depth={width / 1000 * floorRepeat} textureUrl={floorImagePath} />
          :
          <PlainMaterial color={room.floorColor} />
        }
        {/* front wall (furthest from camera) */}
        {
          wallsImagePath !== '' && sceneIsLoaded === true ?
          <CustomWallMaterial width={width / 1000 * wallsRepeat} height={height / 1000 * wallsRepeat} textureUrl={wallsImagePath} />
          :
          <PlainMaterial color={room.wallsColor} />
        }
        {/* back wall (nearest to camera) */}
        {
          wallsImagePath !== '' && sceneIsLoaded === true ?
          <CustomWallMaterial width={width / 1000 * wallsRepeat} height={height / 1000 * wallsRepeat} textureUrl={wallsImagePath} />
          :
          <PlainMaterial color={room.wallsColor} />
        }
      </mesh>
  )
}

export default RoomWalls;