import { useEffect } from 'react';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';

const CustomWallTexturePrimitive = (props) => {

  const { textureUrl, repeat } = props;
  const texture = useTexture(textureUrl);
  
  useEffect(() => {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    if (repeat) {
      if (repeat[0] && repeat[1]) {
        texture.repeat.set(repeat ? repeat[0] : 4, repeat ? repeat[1] : 4);
      }
    }
  }, [texture, repeat]);

  return (
    <primitive
      object={texture}
      attach="map"
    />
  );
}

export default CustomWallTexturePrimitive;