import { Text } from '@react-three/drei';
import * as THREE from 'three';

const AxisHelper = (props) => {

  const { isPreview } = props;

  if (isPreview === true) {
    return (
      <group
        scale={[2, 2, 2]}
        position={[0, 35, 0]}
      >
        <mesh position={[10, 0, 0]}>
          <boxBufferGeometry args={[20, 0.1, 0.1]} />
          <meshBasicMaterial depthwrite={false} color={'red'} side={THREE.DoubleSide} />
          <Text
            text="-     x     +"
            fontSize={3}
            lineHeight={1}
            position={[0, 3, 0]}
          >
            <meshBasicMaterial depthwrite={false} color={'red'} side={THREE.DoubleSide} />
          </Text>
        </mesh>
        <mesh position={[0, 10, 0]}>
          <boxBufferGeometry args={[0.1, 20, 0.1]} />
          <meshBasicMaterial depthwrite={false} color={'limegreen'} side={THREE.DoubleSide} />
          <Text
            text={`+\n\ny\n\n-`}
            fontSize={3}
            lineHeight={1}
            position={[-3, 0, 0]}
          >
            <meshBasicMaterial depthwrite={false} color={'limegreen'} side={THREE.DoubleSide} />
          </Text>
        </mesh>
        <mesh position={[0, 0, 10]}>
          <boxBufferGeometry args={[0.1, 0.1, 20]} />
          <meshBasicMaterial depthwrite={false} color={'blue'} side={THREE.DoubleSide} />
          <Text
            text="+     z     -"
            fontSize={3}
            lineHeight={1}
            position={[0, 3, 0]}
            rotation={[0, Math.PI / 2, 0]}
          >
            <meshBasicMaterial depthwrite={false} color={'blue'} side={THREE.DoubleSide} />
          </Text>
        </mesh>
      </group>
    );
  } else {
    return null;
  }
}

export default AxisHelper;