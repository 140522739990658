import { Fragment, useRef, useState } from 'react';
import FloorHoverNavSpace from '../../../utils/ThreeHelpers/FloorHoverNavSpace';
import PlayHouse from './PlayHouse';
import Terrain from './Terrain';
import GardenContents from './GardenContents';
import SoldSign from './SoldSign';
import SkyElement from './SkyElement';

const GardenItems = (props) => {

  const { floorRef, isHoldingPage, garden, sceneIsLoaded } = props;

  return (
    <group>
      <group position={[0, 60, 0]}>
        {
          garden &&
          <GardenContents project={garden} {...props} />
        }
      </group>
      {
        sceneIsLoaded === true &&
        <group>
          <group position={[120, 0, 0]}>
            <PlayHouse {...props} />
          </group>
          {
            isHoldingPage === true &&
            <SoldSign />
          }
          <Terrain {...props} floorRef={floorRef} />
        </group>
      }
    </group>
  )
}

const Scene = (props) => {

  const floorRef = useRef();
  const [hours, setHours] = useState(new Date().getHours());
  const [isNight, setIsNight] = useState(hours > 19 || hours < 7 ? true : false);
  const [room] = useState({
    width: 600,
    height: 170,
    depth: 600
  });

  return (
    <Fragment>
      <FloorHoverNavSpace
        room={room}
        floorRef={floorRef}
        floorWidth={room.width}
        floorDepth={room.depth}
        transparent={true}
        position={[0, 40, 60]}
        target={[0, 16, -60]}
        forbiddenPositions={[{
          from: {
            x: -30,
            z: -190
          },
          to: {
            x: 30,
            z: -90
          }
        }]}
        {...props}
        isGarden={true}
      >
        {
          room &&
          <GardenItems floorRef={floorRef} {...props} room={room} roomHeight={room.height} hours={hours} setHours={setHours} isNight={isNight} setIsNight={setIsNight} />
        }
      </FloorHoverNavSpace>
      <SkyElement {...props} hours={hours} setHours={setHours} isNight={isNight} setIsNight={setIsNight} />
      {
        isNight === false &&
        <ambientLight intensity={0.2} />
      }
      <directionalLight intensity={isNight === true ? 0.2 : 0.6} position={[0, 2, 3]} />
    </Fragment>
  );
}

export default Scene;
export { GardenItems };