import { useRef } from 'react';
import * as THREE from 'three';
import RoomWallsEnvMap from './RoomWallsEnvMap';

const PlainMaterial = (props) => {

  const { color, side } = props;
  const material = useRef();

  return (
    <meshPhysicalMaterial
      ref={material}
      color={color}
      attachArray="material"
      side={side ? side : THREE.FrontSide}
      metalness={0.7}
      roughness={0.7}
      reflectivity={0.5}
      needsUpdate={true}
    >
      <RoomWallsEnvMap material={material} />
    </meshPhysicalMaterial>
  )
};

export default PlainMaterial;