import { useRef, useEffect } from 'react';

const PageItemSectionShopifyBuyButton = (props) => {

  const { item } = props;
  const embedWrapper = useRef();

  useEffect(() => {
    let scriptContent;
    let scriptId = `script` + new Date().getTime().toString() + Math.floor(Math.random() * 400);

    const { embed } = item.settings;

    if (embed) {
      embedWrapper.current.innerHTML = embed.substring(0, embed.indexOf(`<script`));
      scriptContent = embed.substring(embed.indexOf(`<script type="text/javascript">`) + 31, embed.indexOf(`</script`) - 1);
    }

    const loadGMaps = (callback) => {
      const existingScript = document.querySelector(`#${scriptId}`);
      if (!existingScript) {
        const script = document.createElement(`script`);
        // script.src = `https://maps.googleapis.com/maps/api/js`;
        script.innerHTML = scriptContent;
        script.id = scriptId;
        document.body.appendChild(script);
        script.onload = () => {
          if (callback) callback();
        };
      }
      if (existingScript && callback) callback();
    };

    loadGMaps((e) => console.log(e));

    const unloadScript = () => {
      const existingScript = document.querySelector(`#${scriptId}`);
      if (existingScript) {
        document.querySelector(`#${scriptId}`).parentNode.removeChild(document.querySelector(`#${scriptId}`));
      }
    }

    return () => {
      unloadScript();
    }
  }, [item.settings]);

  if (item.settings.embed) {
    return (
      <div
        ref={embedWrapper}
        className="col-xxs-12 page__item__embed__wrapper"
      >{item.settings.embed}</div>
    );
  } else {
    return null;
  }
}

export default PageItemSectionShopifyBuyButton;