import { useEffect } from 'react';

const ItemFreePositioner = (props) => {

  const { setPositionX, setPositionZ, setRotation, item, room, width, height, depth, isPreview, previewItem } = props;
  const { value } = item;
  const { positionX, positionZ, rotation } = value;

  useEffect(() => {
    let roomDimensions = {
      width: room ? room.width : width,
      height: 70,
      depth: room ? room.depth : depth
    }
    if (isPreview === true && previewItem.collection === 'gardenItems') {
      roomDimensions.width = 600;
      roomDimensions.height = 170;
      roomDimensions.depth = 600;
    }

    // const xPosition = Math.max(-roomDimensions.width * 0.5 + width * 0.5, Math.min(roomDimensions.width * 0.5 - width * 0.5, (positionX - 50) * 0.01 * roomDimensions.width));
    const xPosition = (positionX - 50) * 0.01 * roomDimensions.width;
    setPositionX(xPosition);
    // const zPosition = Math.max(-roomDimensions.depth * 0.5 + depth * 0.5, Math.min(roomDimensions.depth * 0.5 - depth * 0.5, (positionZ - 50) * 0.01 * roomDimensions.depth));
    const zPosition = (positionZ - 50) * 0.01 * roomDimensions.depth;
    setPositionZ(zPosition);
    setRotation(rotation / 180 * Math.PI);
  }, [width, height, depth, positionX, positionZ, setPositionX, setPositionZ, setRotation, room, rotation, isPreview, previewItem.collection]);

  return null;
}

export default ItemFreePositioner;