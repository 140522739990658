import { Html } from '@react-three/drei';
import { useEffect } from 'react';

const ItemVerticalPositioner = (props) => {

  const { setPositionY, item, width, height, depth, roomHeight, scaleMultiplier, positionX, positionY, positionZ, boxBounds } = props;
  const { value } = item;
  const { fixedToFloor, scale } = value;

  useEffect(() => {
    if (fixedToFloor === true || item.field.name === 'door') {
      if (item.field.name !== 'door') {
        setPositionY((-roomHeight * 0.5 + 10) + ((height * 0.5)));
      } else {
        setPositionY((-roomHeight * 0.5 + 10) + ((90 * 0.5)));
      }
    } else {

      // const yPosition = Math.max(-roomHeight * 0.5 + height * 0.5, Math.min(roomHeight * 0.5 - height * 0.5, (parseFloat(value.positionY) - 50) * 0.01 * roomHeight));
      const yPosition = (parseFloat(value.positionY) - 50) * 0.01 * roomHeight;

      setPositionY(yPosition);
    }
  }, [height, value.positionY, setPositionY, fixedToFloor, roomHeight, value.scale, scaleMultiplier, scale, boxBounds, item.field]);

  if (props.isDev === true) {
    return (
      <mesh position={[positionX, positionY, positionZ]}>
        <boxBufferGeometry args={[width, height, depth]} />
        <meshPhongMaterial color={'hotpink'} transparent={true} opacity={0.9} wireframe={true} />
        <Html>
          <p
            style={{
              color: 'limegreen',
              userSelect: 'none'
            }}
          >
            {positionY - (height / 2)}
            {/* Scale: {scale},<br />height: {height},<br />roomHeight: {roomHeight},<br />positionY: {positionY} */}
          </p>
        </Html>
      </mesh>
    );
  } else {
    return null;
  }
}

export default ItemVerticalPositioner;