import { useEffect, useState, useRef } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { getImage } from '../../../../utils/cockpit';
import CustomFloorMaterial from './CustomFloorMaterial';
import PlainMaterial from './PlainMaterial';

const CustomSkydomeTexturePrimitive = (props) => {

  const { textureUrl } = props;
  const texture = useTexture(textureUrl);

  return (
    <primitive
      attach="map"
      object={texture}
      wrapS={THREE.EquirectangularReflectionMapping}
      wrapT={THREE.EquirectangularReflectionMapping}
      format={THREE.RGBFormat}
      encoding={THREE.sRGBEncoding}
      needsUpdate={true}
      anisotropy={4}
    />
  );
}

const CustomSkydomeMaterial = (props) => {

  const { wallsImagePath, color } = props;

  const material = useRef();

  return (
    <meshPhysicalMaterial
      color={color ? color : '#fff'}
      side={THREE.BackSide}
      needsUpdate={true}
      ref={material}
    >
      <CustomSkydomeTexturePrimitive textureUrl={wallsImagePath} />
    </meshPhysicalMaterial>
  )
}

const RoomSkydome = (props) => {

  const { width, height, depth, walls, floor, floorRepeat, room } = props;

  const [wallsImagePath, setWallsImagePath] = useState('');
  const [floorImagePath, setFloorImagePath] = useState('');

  useEffect(() => {

    if (walls) {
      if (walls.path && walls.path !== '') {
        getImage(walls.path, 2048, 2048, 60, setWallsImagePath);
      }
    }
    if (floor) {
      if (floor.path && floor.path !== '') {
        getImage(floor.path, 2048, 2048, 60, setFloorImagePath);
      }
    }
  }, [walls, floor]);

  return (
    <group>
      <mesh>
        <sphereBufferGeometry args={[ Math.min(width, depth), 64, 64]} />
        {
          wallsImagePath !== '' ?
          <CustomSkydomeMaterial wallsImagePath={wallsImagePath} />
          :
          <PlainMaterial color={room.wallsColor} />
        }
      </mesh>
      <mesh position={[0, 70, 0]}>
        <boxBufferGeometry args={[-width * 4, height, depth * 4]} name="room walls" />
        {/* left wall */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        {/* right wall */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        {
          floorImagePath !== '' ?
            <CustomFloorMaterial width={width * 4 / 1000 * floorRepeat} depth={depth * 4 / 1000 * floorRepeat} textureUrl={floorImagePath} />
          :
          <PlainMaterial color={room.floorColor} />
        }
        {/* front wall (furthest from camera) */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
        {/* back wall (nearest to camera) */}
        <meshBasicMaterial attachArray={'material'} transparent={true} opacity={0} />
      </mesh>
    </group>
  )
}

export default RoomSkydome;