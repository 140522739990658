import { Link } from 'react-router-dom';

const PageItemSectionButton = (props) => {

  const { item } = props;

  if (item.settings.url && item.settings.text) {
    if (item.settings.url.indexOf(window.origin) > -1) {
      return (
        <Link to={item.settings.url.replace(window.origin, '')}>{item.settings.text}</Link>
      );
    } else {
      return (
        <div className="page__item__link__wrapper col-xxs-12">
          <a
            href={item.settings?.url}
            rel="noreferrer"
            target="_blank"
          >
            {item.settings.text}
          </a>
        </div>
      )
    }
  } else {
    return null;
  }
}

export default PageItemSectionButton;