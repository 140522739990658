import Grass from './Grass';

const Terrain = (props) => {

  return (
    <group
      position={[0, 70, 0]}
    >
      <group
        position={[0, -84, -120]}
        scale={[2, 2, 2]}
      >
        <Grass {...props} />
      </group>
    </group>
  );
}

export default Terrain;