import { useEffect } from 'react';

const ItemOnWallPositioner = (props) => {

  const { setPositionX, setPositionZ, setRotation, setFocusedItemPosition, item, room, width, height, depth } = props;
  const { value } = item;
  const { wall, positionOnWall, scale, rotation } = value;

  useEffect(() => {

    let wallPosition = positionOnWall;
    if (positionOnWall === 0) {
      wallPosition = 1;
    }

    if (item.field.name === 'model') {
      setRotation(Math.ceil(rotation / 90) * 90);
    } else {
      if (wall === 'right' || !wall) {
        setRotation(Math.PI / 2);
      } else if (wall === 'left') {
        setRotation(Math.PI / -2);
      } else if (wall === 'front') {
        setRotation(Math.PI);
      } else if (wall === 'back') {
        setRotation(0);
      }
    }

    if (!wall || wall === 'right' || wall === 'left') {
      if (Math.ceil(rotation / 90) * 90 % 180 === 0 && item.field.name === 'model') {
        wallPosition = Math.max(-room.depth * 0.5 + depth * 0.5, Math.min(room.depth * 0.5 - depth * 0.5, (positionOnWall - 50) * 0.01 * room.depth));
      } else {
        wallPosition = Math.max(-room.depth * 0.5 + width * 0.5, Math.min(room.depth * 0.5 - width * 0.5, (positionOnWall - 50) * 0.01 * room.depth));
      }
    } else {
      if (Math.ceil(rotation / 90) * 90 % 180 === 0) {
        wallPosition = Math.max(-room.width * 0.5 + width * 0.5, Math.min(room.width * 0.5 - width * 0.5, (positionOnWall - 50) * 0.01 * room.width));
      } else {
        wallPosition = Math.max(-room.width * 0.5 + depth * 0.5, Math.min(room.width * 0.5 - depth * 0.5, (positionOnWall - 50) * 0.01 * room.width));
      }
    }

    if (wall === 'right' || !wall) {
      if (Math.ceil(rotation / 90) * 90 % 180 === 0 && item.field.name === 'model') {
        setPositionX(-parseFloat(room.width / 2) + width / 2);
      } else {
        setPositionX(-parseFloat(room.width / 2) + depth / 2);
      }
      setPositionZ(wallPosition);
      if (typeof setFocusedItemPosition !== 'undefined') {
        setFocusedItemPosition({
          positionX: parseFloat(room.width / 2) - (Math.max(width, height, depth) * 2.8),
          positionZ: wallPosition,
          lookAt: {
            x: parseFloat(room.width / 2) - width * 0.5,
            y: item.field.name !== 'door' ? 60 : 20,
            z: wallPosition
          }
        });
      }
    }
    if (wall === 'left') {
      if (Math.ceil(rotation / 90) * 90 % 180 === 0 && item.field.name === 'model') {
        setPositionX(parseFloat(room.width / 2) - width / 2);
      } else {
        setPositionX(parseFloat(room.width / 2) - depth / 2);
      }
      setPositionZ(wallPosition);
      if (typeof setFocusedItemPosition !== 'undefined') {
        setFocusedItemPosition({
          positionX: parseFloat(-room.width / 2) + (Math.max(width, height, depth) * 2.8),
          positionZ: wallPosition,
          lookAt: {
            x: parseFloat(-room.width / 2) + width * 0.5,
            y: item.field.name !== 'door' ? 60 : 20,
            z: wallPosition
          }
        });
      }
    }
    if (wall === 'front') {
      setPositionX(wallPosition);
      if (Math.ceil(rotation / 90) * 90 % 180 === 0) {
        setPositionZ(parseFloat(room.depth / 2) - depth * 0.5);
      } else {
        setPositionZ(parseFloat(room.depth / 2) - width * 0.5);
      }
      if (typeof setFocusedItemPosition !== 'undefined') {
        setFocusedItemPosition({
          positionX: wallPosition,
          positionZ: parseFloat(room.depth / 2) - (Math.max(width, height, depth) * 2.8),
          lookAt: {
            x: wallPosition,
            y: item.field.name !== 'door' ? 60 : 20,
            z: parseFloat(room.depth / 2) - depth * 0.5
          }
        });
      }
    }
    if (wall === 'back') {
      setPositionX(wallPosition);
      if (Math.ceil(rotation / 90) * 90 % 180 === 0) {
        setPositionZ(parseFloat(-room.depth / 2) + depth * 0.5);
      } else {
        setPositionZ(parseFloat(-room.depth / 2) + width * 0.5);
      }
      if (typeof setFocusedItemPosition !== 'undefined') {
        setFocusedItemPosition({
          positionX: wallPosition,
          positionZ: parseFloat(-room.depth / 2) + (Math.max(width, height, depth) * 2.8),
          lookAt: {
            x: wallPosition,
            y: item.field.name !== 'door' ? 60 : 20,
            z: parseFloat(-room.depth / 2) + depth * 0.5
          }
        });
      }
    }

  }, [wall, positionOnWall, value.width, setFocusedItemPosition, setPositionX, setPositionZ, rotation, setRotation, room, item.field.name, width, height, depth, props.scaleMultiplier, scale]);

  return null;
}

export default ItemOnWallPositioner;