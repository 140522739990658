import { useRef } from 'react';
import * as THREE from 'three';
import CustomWallTexture from './CustomWallTexture';
import RoomWallsEnvMap from './RoomWallsEnvMap';

const CustomFloorMaterial = (props) => {

  const { width, depth, color, side, isSkyDome } = props;
  const material = useRef();

  return (
    <meshPhysicalMaterial
      attachArray={isSkyDome !== true ? `material` : null}
      color={color ? color : '#ffffff'}
      side={side ? side : THREE.FrontSide}
      metalness={0.1}
      roughness={0.4}
      reflectivity={0.2}
      needsUpdate={true}
      ref={material}
    >
      <CustomWallTexture
        material={material}
        repeat={[width ? -width : -4, depth ? depth : 4]}
        {...props}
      />
      <RoomWallsEnvMap material={material} />
    </meshPhysicalMaterial>
  )
};

export default CustomFloorMaterial;