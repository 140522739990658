import { useRef, useState, useEffect, Suspense } from 'react';
import { useFrame } from '@react-three/fiber';
import { A11y } from '@react-three/a11y';
import { OrbitControls } from '@react-three/drei';
import lerp from '../lerp';
import Camera from './Camera';
import Floor from './Floor';
import FloorHover from './FloorHover';
import AxisHelper from './AxisHelper';

const FloorHoverNavSpace = (props) => {
  const { children, floorWidth, floorDepth, focusedItem, setFocusedItem, position, target, forbiddenPositions, isPreviewStyleNav, isDev, userIsTouching,
  room, isGarden, sceneIsLoaded
  } = props;
  const { entryPositionX, entryPositionZ, entryLookAtX, entryLookAtZ } = room;

  const [lerpSpeed] = useState(0.1);
  const cameraTargetPosition = useRef({
    ...position
  });
  const floorHover = useRef();
  const camera = useRef();
  const controls = useRef();
  const [isClick, setIsClick] = useState(true);
  const clickTimeout = useRef();
  const [isZooming, setIsZooming] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const hideFloorHover = () => {
    if (floorHover.current) {
      floorHover.current.visible = false;
    }
  }

  const moveFloorHover = (e) => {
    if (floorHover.current) {
      if (floorHover.current) {
        floorHover.current.position.set(
          e.point.x,
          -9.8,
          e.point.z
        );

        if (forbiddenPositions) {
          if (forbiddenPositions[0]) {
            for (let forbiddenPosition of forbiddenPositions) {
              if (typeof forbiddenPosition.from?.x !== 'undefined' && typeof forbiddenPosition.to?.x !== 'undefined' && typeof forbiddenPosition.from?.z !== 'undefined' && typeof forbiddenPosition.to?.z !== 'undefined') {
                if (e.point.x >= forbiddenPosition.from.x && e.point.x <= forbiddenPosition.to.x && e.point.z >= forbiddenPosition.from.z && e.point.z <= forbiddenPosition.to.z) {
                  hideFloorHover();
                } else {
                  floorHover.current.visible = true;
                }
              } else {
                floorHover.current.visible = true;
              }
            }
          } else {
            floorHover.current.visible = true;
          }
        } else {
          floorHover.current.visible = true;
        }
      }
      props.setHoveredItem({});
    }
  }

  const moveToPoint = (e) => {

    setHasInteracted(true);
    if (isClick === true) {
      setIsZooming(false);

      const x = Math.max(-(floorWidth) * 0.4, Math.min((floorWidth) * 0.4, (e.point.x)));
      const z = Math.max(-(floorDepth) * 0.4, Math.min((floorDepth) * 0.4, (e.point.z)));

      if (e.intersections[0] && isClick === true) {
        if (forbiddenPositions) {
          if (forbiddenPositions[0]) {
            for (let forbiddenPosition of forbiddenPositions) {
              if (typeof forbiddenPosition.from?.x !== 'undefined' && typeof forbiddenPosition.to?.x !== 'undefined' && typeof forbiddenPosition.from?.z !== 'undefined' && typeof forbiddenPosition.to?.z !== 'undefined') {
                if (e.point.x >= forbiddenPosition.from.x && e.point.x <= forbiddenPosition.to.x && e.point.z >= forbiddenPosition.from.z && e.point.z <= forbiddenPosition.to.z) {
                  return;
                }
              }
            }
          }
        }
        if (e.intersections[0].eventObject?.name === 'floor') {
          cameraTargetPosition.current = {
            x: x,
            y: 20,
            z: z
          };

          if (focusedItem.type === 'area item') {
            props.history.push(`/area/${focusedItem.exhibition.name_slug}`);
          } else if (focusedItem.type === 'main exhibition item') {
            props.history.push('/');
          }
          setFocusedItem({});
          props.setHoveredItem({});
        }
      }
    }
  }

  useEffect(() => {

    if (isGarden!== true && sceneIsLoaded === true) {
      let posX = entryPositionX ?? 0;
      let posZ = entryPositionZ ?? 0;
      let lookAtX = entryLookAtX ?? 0;
      let lookAtZ = entryLookAtZ ?? 0;


      const moveTo = (e) => {
        setIsZooming(false);
        const x = Math.max(-(floorWidth) * 0.4, Math.min((floorWidth) * 0.4, (e.point.x)));
        const z = Math.max(-(floorDepth) * 0.4, Math.min((floorDepth) * 0.4, (e.point.z)));
        cameraTargetPosition.current = { x: x, y: 20, z: z };
        camera.current.position.set(x, 20, z);
        camera.current.lookAt(lookAtX, 20, lookAtZ);
      }

      setIsClick(true);
      moveTo({
        point: {
          x: posX,
          z: posZ
        }
      });
    }
  }, [entryPositionX, entryPositionZ, entryLookAtX, entryLookAtZ, floorWidth, floorDepth, isPreviewStyleNav, isGarden, sceneIsLoaded]);

  const handleMouseDown = (e) => {
    setIsClick(true);
    clearTimeout(clickTimeout.current);
    clickTimeout.current = setTimeout(() => {
      setIsClick(false);
    }, 700);
  }

  useEffect(() => {
    if (userIsTouching === false && floorHover.current && isZooming === false) {
      floorHover.current.visible = true;
    }
  }, [userIsTouching, isZooming]);

  useEffect(() => {

    const handleWheel = (e) => {
      setIsZooming(true);
      if (floorHover.current) {
        floorHover.current.visible = false;
      }
    }

    if (isPreviewStyleNav === false) {
      window.addEventListener('wheel', handleWheel);
    }

    return () => {
      window.removeEventListener('wheel', handleWheel);
    }

  }, [isPreviewStyleNav, camera, userIsTouching]);

  useEffect(() => {

    const x = Math.max(-(floorWidth) * 0.4, Math.min((floorWidth) * 0.4, floorWidth * 0.1));
    const z = Math.max(-(floorDepth) * 0.4, Math.min((floorDepth) * 0.4, floorWidth * 0.1));

    cameraTargetPosition.current = {
      x: x,
      y: 20,
      z: z
    };
  }, [floorWidth, floorDepth])

  useEffect(() => {
    if (props.location.pathname === '/') {
      cameraTargetPosition.current = {
        x: 0,
        y: 20,
        z: 0
      };

      camera.current.position.set(cameraTargetPosition.current.x, cameraTargetPosition.current.y, cameraTargetPosition.current.z);

      camera.current.lookAt(0, 20, 0);
      setFocusedItem({});
    }
  }, [props.location.pathname, setFocusedItem, floorDepth, floorWidth, focusedItem.positionX]);

  useEffect(() => {
    if (typeof focusedItem.positionX !== 'undefined') {
      cameraTargetPosition.current = {
        x: Math.max(-(floorWidth) * 0.4, Math.min((floorWidth) * 0.4, (focusedItem.positionX))),
        y: 20,
        z: Math.max(-(floorDepth) * 0.4, Math.min((floorDepth) * 0.4, (focusedItem.positionZ))),
        lookAt: {...focusedItem.lookAt}
      };

      if (focusedItem.lookAt) {
        camera.current.lookAt(focusedItem.lookAt.x, focusedItem.lookAt.y, focusedItem.lookAt.z);
      }
    }
  }, [focusedItem, floorDepth, floorWidth, props.location.pathname]);

  useFrame(({ clock }) => {
    if (camera.current?.position && cameraTargetPosition.current?.x) {
      if (hasInteracted === true) {
        if (isZooming === false) {
          if (controls.current) {
            const controlsPoint = controls.current.target;
            const controlsPosition = controls.current.position0;
            const camPos = position;
            if (camPos.x) {
              controlsPosition.set(camPos.x, camPos.y, camPos.z);
            }
            if (!cameraTargetPosition.current.lookAt) {
              controlsPoint.x = cameraTargetPosition.current.x;
              controlsPoint.y = cameraTargetPosition.current.y;
              controlsPoint.z = cameraTargetPosition.current.z;
            } else {
              controlsPoint.x = cameraTargetPosition.current.lookAt.x;
              controlsPoint.y = cameraTargetPosition.current.lookAt.y;
              controlsPoint.z = cameraTargetPosition.current.lookAt.z;
            }
          }

          const cameraPoint = camera.current.position;
          cameraPoint.x = lerp(cameraPoint.x, cameraTargetPosition.current.x, lerpSpeed);
          cameraPoint.y = lerp(cameraPoint.y, cameraTargetPosition.current.y, lerpSpeed);
          cameraPoint.z = lerp(cameraPoint.z, cameraTargetPosition.current.z, lerpSpeed);

          if (
            Math.round(cameraPoint.x * 100) === Math.round(100 * cameraTargetPosition.current.x) &&
            Math.round(cameraPoint.z * 100) === Math.round(100 * cameraTargetPosition.current.z)
          ) {
            cameraTargetPosition.current = {};
          }

          if (controls.current) {
            controls.current.update();
          }
        }
      }
    } else {
      if (floorHover.current) {
        const { elapsedTime } = clock;
        floorHover.current.scale.set(
          Math.sin(elapsedTime) / 12 + 0.75, Math.sin(elapsedTime) / 12 + 0.75, 1
        );
      }
    }
  });

  return (
    <group>
      <AxisHelper {...props} />
      <Camera {...props} camera={camera} position={position} cameraChildren={props.cameraChildren} />
      <group>
        <Suspense fallback={null}>
          {children}
        </Suspense>
        {
          isPreviewStyleNav === false &&
          <A11y role="button" description={`Click to move`} >
            <Floor
              {...props}
              moveToPoint={moveToPoint}
              moveFloorHover={moveFloorHover}
              hideFloorHover={hideFloorHover}
              handleMouseDown={handleMouseDown}
            />
          </A11y>
        }
      </group>
      <FloorHover {...props} floorHover={floorHover} />
      <OrbitControls
        ref={controls}
        enablePan={isPreviewStyleNav === true || isDev === true ? true : false}
        enableDamping={isZooming === true || userIsTouching === true ? true : false}
        minDistance={isPreviewStyleNav === true || isDev === true || (isZooming === false && userIsTouching === false) ? 0.01 : 0.01}
        maxDistance={isPreviewStyleNav === true || isDev === true || (isZooming === false && userIsTouching === false) ? Infinity : 200}
        enableZoom={isPreviewStyleNav === true || isZooming === true || userIsTouching === true ? true : false}
        // limit the amount you can look up
        maxPolarAngle={Math.PI * 0.6}
        // limit the amount you can look down
        minPolarAngle={Math.PI * 0.4}
        position0={isPreviewStyleNav === true ? [...position] : null}
        target={target ? target : [0, 10, 0]}
      />
    </group>
  )
}

export default FloorHoverNavSpace;