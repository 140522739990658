import { motion } from 'framer-motion';

import PageItemSection from '../Page/PageItems/PageItemSection';

const LandingPageContent = (props) => {
  const { item, setIs3D } = props;
  const { title, content } = item;

  return (
    <motion.div
      key='landingPageContent'
      className="landing-page--2d__content"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.9,
        ease: 'easeInOut'
      }}
    >
      <div className="section row section--page">
        <div className="page__items col-sm-12">
          <div className="page__item row">
            <div className="page__item__title__wrapper col-xxs-12">
              <h2>{title}</h2>
            </div>
            <div className="page__item__content col-xxs-12">
              {
                content &&
                content[0] &&
                content.map(
                  (section, i) => (
                    <PageItemSection {...props} key={i} section={section} setIs3D={setIs3D} itemContent={content} />
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default LandingPageContent;