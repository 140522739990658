import { useRef } from 'react';
import * as THREE from 'three';
import RoomWallsEnvMap from './RoomWallsEnvMap';
import CustomWallTexture from './CustomWallTexture';

const CustomWallMaterial = (props) => {

  const { color, width, height } = props;
  const material = useRef();

  return (
    <meshPhysicalMaterial
      attachArray="material"
      color={color ? color : '#ffffff'}
      side={THREE.FrontSide}
      metalness={0.1}
      roughness={0.4}
      reflectivity={0.2}
      needsUpdate={true}
      ref={material}
    >
      <CustomWallTexture
        material={material}
        repeat={[width ? -width : -4, height ? height : 4]}
        {...props}
      />
      <RoomWallsEnvMap material={material} />
    </meshPhysicalMaterial>
  )
};

export default CustomWallMaterial;