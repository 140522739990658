import { Component, useState } from "react"
import jsonp from "jsonp"
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

class Mailchimp extends Component {
  state = {};

  handleSubmit(evt) {
    evt.preventDefault();
    const { fields, action } = this.props;
    const values = fields.map(field => {
      return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
    }).join("&");
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;
    const email = this.state['EMAIL'];
    (!regex.test(email)) ? this.setState({ status: "empty" }) : this.sendData(url);
  };

  sendData(url) {
    this.setState({ status: "sending" });
    jsonp(url, { param: "c" }, (err, data) => {
      if (data.msg.includes("already subscribed")) {
        this.setState({ status: 'duplicate' });
        this.props.onAlreadySignedUpCallback();
      } else if (err) {
        this.setState({ status: 'error' });
        this.props.onErrorCallback();
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' });
        this.props.onErrorCallback();
      } else {
        this.setState({ status: 'success' });
        this.props.onSuccessCallback();
      };
    });
  }

  render() {
    const { fields, className, buttonClassName } = this.props;
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages
    }
    const { status } = this.state;
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className={className}>
        {fields.map(input =>
        (
          status !== "success" &&
          <input
            {...input}
            key={input.name}
            onChange={({ target }) => this.setState({ [input.name]: target.value })}
            defaultValue={this.state[input.name]}
          />
        )
        )}
        {!status &&
          <button
            disabled={status === "sending"}
            type="submit"
            className={buttonClassName}
          >
            <span>{messages.button}</span>
          </button>
        }
        {status === "sending" && <p className="mailchimp-form__message">{messages.sending}</p>}
        {status === "success" && <p className="mailchimp-form__message">{messages.success}</p>}
        {status === "duplicate" && <p className="mailchimp-form__message">{messages.duplicate}</p>}
        {status === "empty" && <p className="mailchimp-form__message">{messages.empty}</p>}
        {status === "error" && <p className="mailchimp-form__message">{messages.error}</p>}
      </form>
    );
  }
}

Mailchimp.defaultProps = {
  messages: {
    sending: "Sending...",
    success: "Thanks!",
    error: "An unexpected internal error has occurred.",
    empty: "You must write an e-mail.",
    duplicate: "Too many subscribe attempts for this email address",
    button: "Subscribe!"
  },
  buttonClassName: "",
  styles: {
    sendingMsg: {
      color: "#0652DD"
    },
    successMsg: {
      color: "#009432"
    },
    duplicateMsg: {
      color: "#EE5A24"
    },
    errorMsg: {
      color: "#ED4C67"
    }
  },
  onSuccessCallback: () => {},
  onErrorCallback: () => {},
  onAlreadySignedUpCallback: () => {}
};

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  onSuccessCallback: PropTypes.func,
  onErrorCallback: PropTypes.func,
  onAlreadySignedUpCallback: PropTypes.func
};

const MailchimpSignupForm = (props) => {

  const { setMailchimpIsActive } = props;
  const [mailchimpSuccess, setMailchimpSuccess] = useState(false);

  const onSuccessCallback = () => {
    setMailchimpSuccess(true);
    setTimeout(() => {
      setMailchimpIsActive && setMailchimpIsActive(false);
      setTimeout(() => {
        setMailchimpSuccess(false);
      }, [400]);
    }, 600);
  }

  const onErrorCallback = (e) => {
  }

  const onAlreadySignedUpCallback = () => {
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="mailchimp__wrapper"
    >
      <Mailchimp
        action='https://clairebarrow.us9.list-manage.com/subscribe/post?u=09fd011b8349d628f56851d70&amp;id=37ee4a07cd'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
        ]}
        messages = {
          {
            sending: "Sending...",
            success: "Thank you for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "You must write an e-mail.",
            duplicate: "This email address is already subscribed!",
            button: "Subscribe!"
          }
        }
        onSuccessCallback={onSuccessCallback}
        onErrorCallback={onErrorCallback}
        onAlreadySignedUpCallback={onAlreadySignedUpCallback}
        resetOnSuccess={true}
        className="mailchimp-form"
        buttonClassName="mailchimp-form__button--submit"
        mailchimpSuccess={mailchimpSuccess}
      />
    </motion.div>
  )
}

export default MailchimpSignupForm;