import { useRef, useEffect, Fragment } from 'react';
import { cockpitUrl } from '../utils/cockpit';

const Music = (props) => {
  const music = useRef();

  const { backgroundMusic, setBackgroundMusic, location, rooms, previewItem, siteIsMuted, setSiteIsMuted, toolTip } = props;
  const { pathname } = location;

  useEffect(() => {
    let backgroundMusicString = '';
    if (music.current) {
      music.current.pause();
    }
    if (pathname.indexOf('/room/') === 0) {
      const slug = pathname.replace('/room/', '');
      for (let room of rooms) {
        if (room.title_slug === slug || room._id === slug) {
          if (room.backgroundMusic && room.backgroundMusic !== '') {
            if (music.current) {
              music.current.pause();
            }
            backgroundMusicString = cockpitUrl + '/' + room.backgroundMusic;
          }
        }
      }
    }
    if (!previewItem._id) {
      setBackgroundMusic(backgroundMusicString);
    }
  }, [pathname, rooms, setBackgroundMusic, music, previewItem._id]);

  useEffect(() => {
    if (music.current) {
      music.current.pause();
    }
    if (previewItem.backgroundMusic && previewItem.backgroundMusic !== '') {
      setBackgroundMusic(cockpitUrl + '/' + previewItem.backgroundMusic);
    } else {
      setBackgroundMusic('');
    }
  }, [setBackgroundMusic, music, previewItem.backgroundMusic]);

  useEffect(() => {
    const playBackgroundMusic = () => {
      if (music.current) {
        music.current.currentTime = 0;
        music.current.play().then(() => {
          window.removeEventListener('click', playBackgroundMusic);
        })
        .catch(error => console.log(error));
      }
    }

    window.addEventListener('click', playBackgroundMusic);

    return () => {
      window.removeEventListener('click', playBackgroundMusic);
    }
  }, [backgroundMusic, music]);

  if (backgroundMusic && backgroundMusic !== '') {
    return (
      <Fragment>
        {
          backgroundMusic && backgroundMusic !== '' && !props.overlayContent.value && toolTip === '' &&
          <button
            className="mute-button"
              onClick={() => {
                if (siteIsMuted === false) {
                  music.current.pause();
                } else {
                  music.current.play().catch((error) => console.log(error));
                }
                setSiteIsMuted(!siteIsMuted);
              }}
          >{siteIsMuted === true ? 'unmute' : 'mute'}</button>
        }
        <audio
          src={backgroundMusic}
          ref={music}
          volume={siteIsMuted === true ? 0 : 1}
        />
      </Fragment>
    );
  } else {
    return null;
  }
};

export default Music;