import { useState, useEffect } from 'react';

const EntryPositionAndLookAtPositionHelpers = (props) => {

  const { isPreview, entryPositionX, entryPositionZ, entryLookAtX, entryLookAtZ } = props;
  const [entryPosition, setEntryPosition] = useState([0, 0, 0]);
  const [entryLookAt, setEntryLookAt] = useState([0, 0, 0]);

  useEffect(() => {
    let entryPositionObject = [0, 0, 0];
    let entryLookAtObject = [0, 0, 0];

    if (entryPositionX && entryPositionX !== '') {
      entryPositionObject[0] = parseFloat(entryPositionX);
    }
    if (entryPositionZ && entryPositionZ !== '') {
      entryPositionObject[2] = parseFloat(entryPositionZ);
    }

    setEntryPosition(entryPositionObject);

    if (entryLookAtX && entryLookAtX !== '') {
      entryLookAtObject[0] = parseFloat(entryLookAtX);
    }
    if (entryLookAtZ && entryLookAtZ !== '') {
      entryLookAtObject[2] = parseFloat(entryLookAtZ);
    }

    setEntryLookAt(entryLookAtObject);

  }, [entryPositionX, entryPositionZ, entryLookAtX, entryLookAtZ]);

  if (isPreview === true) {
    return (
      <group>
        <mesh position={entryPosition}>
          <sphereBufferGeometry args={[1, 32]} />
          <meshBasicMaterial color={'red'} />
        </mesh>
        <mesh position={entryLookAt}>
          <sphereBufferGeometry args={[1, 32]} />
          <meshBasicMaterial color={'blue'} />
        </mesh>
      </group>
    );
  } else {
    return null;
  }
}

export default EntryPositionAndLookAtPositionHelpers;