import { AnimatePresence, motion } from 'framer-motion';
import mapGif from '../../../assets/UI/map-loop3.gif';
import mapBack from '../../../assets/UI/back-arrow.png';

const MapButton = (props) => {

  const { mapIsActive, setMapIsActive } = props;

  return (
    <button
      className={`map__button ${mapIsActive === true ? 'close' : 'open'}`}
      aria-label={`${mapIsActive === true ? 'close' : 'open'} map`}
      onClick={() => setMapIsActive(!mapIsActive)}
    >
      <AnimatePresence exitBeforeEnter>
        {
          mapIsActive === true ?
            <motion.div
              key={'map button back img'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="map__button__close"
            >
              <img
                className="map__button__img"
                src={mapBack}
                alt="A pinky-purple back arrow."
              />
            </motion.div>
          :
            <motion.img
              key={'map button img'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="map__button__img pixelated"
              src={mapGif}
              alt="A lilac orb with a pink 3d ring encircling it and softly flickering lights."
            />
        }
      </AnimatePresence>
      </button>
  )
}

export default MapButton;