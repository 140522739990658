import PageGridConditionalSectionWrapper from './PageGridConditionalSectionWrapper';
import PageItemSectionGridColumn from './PageItemSectionGridColumn';

const PageItemSectionGrid = (props) => {

  const { item, section, setIs3D } = props;

  if (item.columns) {
    if (item.columns[0]) {
      return (
        <div className="col-xxs-12">
        <div className="row">
          <PageGridConditionalSectionWrapper section={section}>
            {
              item.columns.map(
                (column, i) => (
                  <PageItemSectionGridColumn {...props} column={column} key={i} className={`col-xxs-12 ${item.columns.length >= 3 ? 'col-xs-6 col-sm-4' : item.columns.length === 2 ? 'col-xs-6' : ''}`} setIs3D={setIs3D} />
                )
              )
            }
          </PageGridConditionalSectionWrapper>
          </div>
        </div>
      );
    } else {
      return null
    }
  } else {
    return null
  }
}

export default PageItemSectionGrid;