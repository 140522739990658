import { cockpitUrl } from '../../../../utils/cockpit';
import { Fragment, useState } from 'react';
import WysiwygContent from '../../WysiwygContent';

const PageItemSectionText = (props) => {

  const { item } = props;
  const { settings } = item;
  const [itemClassSuffix] = useState(Math.floor(Math.random() * 999));
  const { text, fontSize, glow, glowColor, font } = settings;

  if (text) {
    return (
      <Fragment>
        <style>
          {
          font && font !== '' ? `

          @font-face {
            font-family: 'custom font ${itemClassSuffix}';
            src: url('${cockpitUrl + '/' + font}') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          .custom-text--${itemClassSuffix} {
            font-family: 'custom font ${itemClassSuffix}', sans-serif;
            text-shadow: ${glow === true ? `0 0 0.2em ${glowColor ? glowColor : '#fff'}` : 'none'}
          }

          .custom-text--${itemClassSuffix} * {
            font-family: inherit;
            font-size: inherit;
          }`
          :
          ''
        }
        </style>
        <div className={`col-xxs-12 custom-text--${itemClassSuffix}`}
          style={{
            fontSize: fontSize ? `${fontSize}rem` : null
          }}
        >
          <WysiwygContent {...props} content={text} />
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
}

export default PageItemSectionText;