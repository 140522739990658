import VideoPlayer from '../../../VideoPlayer';

const PageItemSectionVideo = (props) => {
  const { item } = props;
  const { settings } = item;
  const { aspectRatioWidth, aspectRatioHeight } = settings;

  if (item.settings?.video && item.settings?.video !== '') {
    return (
      <div className="col-xxs-12">
      <VideoPlayer
        url={item.settings.video}
        poster={item.settings.poster ? item.settings.poster : null}
        aspectRatio={aspectRatioWidth && parseFloat(aspectRatioWidth) !== 0 && aspectRatioHeight && parseFloat(aspectRatioHeight) !== 0 ? parseFloat(aspectRatioHeight) / parseFloat(aspectRatioWidth) : 9 / 16}
        {...props}
        />
      </div>
    );
  } else if (item.settings?.url && item.settings?.url !== '') {
    return (
      <div className="col-xxs-12">
        <VideoPlayer
          url={item.settings.url}
          poster={item.settings.poster ? item.settings.poster : null}
          aspectRatio={aspectRatioWidth && parseFloat(aspectRatioWidth) !== 0 && aspectRatioHeight && parseFloat(aspectRatioHeight) !== 0 ? parseFloat(aspectRatioHeight) / parseFloat(aspectRatioWidth) : 9 / 16}
          {...props}
        />
      </div >
    );
  } else {
    return null;
  }
}

export default PageItemSectionVideo;