import { Link } from "react-router-dom";

const Map2DRoom = (props) => {

  const { item, setMapIsActive, isPage2DOnly } = props;
  const { title, title_slug } = item;

  return (
    <li className="map--2D__room">
      <Link
        to={`/${ isPage2DOnly === true ? 'page' : 'room'}/${title_slug && title_slug !== '' ? title_slug : item._id}`}
        onClick={() => setMapIsActive(false)}
        className="map--2D__room__link"
      >{title}</Link>
    </li>
  )
}

const Map2D = (props) => {

  const { rooms, pages2DOnly } = props;

  return (
    <nav className="map--2D">
      <div className="map--2D__inner">
        <ul className="map--2D__list">
          {
            rooms &&
            rooms[0] &&
            rooms.map(
              (item, i) => (
                <Map2DRoom
                  item={item}
                  key={i}
                  index={i}
                  {...props}
                />
              )
            )
          }
          <br />
          {
            pages2DOnly &&
            pages2DOnly[0] &&
            pages2DOnly.map(
              (item, i) => (
                item.showOn2DMenu === true &&
                <Map2DRoom
                  item={item}
                  isPage2DOnly={true}
                  key={i}
                  index={i}
                  {...props}
                />
              )
            )
          }
        </ul>
      </div>
    </nav>
  )
}

export default Map2D;