import { motion } from 'framer-motion';

const Instructions = (props) => {

  const { setInstructionsAreActive, siteOptions } = props;
  const { navInstructionText, navInstructionBackgroundColor, navInstructionButtonColor } = siteOptions;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{opacity: 0}}
      className = "nav--3d-instructions"
    >
      <div
        className="nav--3d-instructions__inner"
        style={{ backgroundColor: navInstructionBackgroundColor ? navInstructionBackgroundColor : null }}
      >
        <button
          className="nav--3d-instructions__button--close"
          onClick={() => setInstructionsAreActive(false)}
          style={{ backgroundColor: navInstructionButtonColor ? navInstructionButtonColor : null }}
        >Close</button>
        <div className="nav--3d-instructions__content">
          {
            navInstructionText && navInstructionText !== '' ?
              <p>{navInstructionText}</p>
              :
            <p>Welcome! Move around by clicking points on the floor, and drag or swipe to look around. Click objects to interact with them.</p>
          }
        </div>
      </div>
    </motion.div>
  )
}

export default Instructions;