import WysiwygContent from '../../WysiwygContent';

const PageItemSectionHtml = (props) => {

  const { item } = props;

  if (item.settings.html) {
    return <div className="page__item__html__wrapper col-xxs-12">
      <WysiwygContent {...props} content={item.settings.html} />
    </div>
  } else {
    return null;
  }
}

export default PageItemSectionHtml;