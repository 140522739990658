import { Suspense } from 'react';
import { A11y } from '@react-three/a11y';

const Door = (props) => {
  const { positionX, positionY, positionZ, rotation, setToolTip, item, isPreview } = props;

  const handleClick = () => {
    if (item?.value?.room?._id && props.rooms && props.rooms[0]) {
      let roomLink = '';
      const id = item.value.room._id;
      for (let room of props.rooms) {
        if (room._id === id) {
          roomLink = `/room/${room.title_slug && room.title_slug !== '' ? room.title_slug : room._id}`;
        }
      }
      if (roomLink !== '' && isPreview === false) {
        props.history.push(roomLink);
      }
    }
  }

  return (
    <Suspense fallback={null}>
      <group
        position={[
          positionX ? positionX : 0,
          positionY,
          positionZ ? positionZ : 0
        ]}
        rotation={[0, rotation ? rotation : 0, 0]}
        onPointerDown={handleClick}
      >
        <pointLight intensity={0.1} color={'#eeeeff'} />
        <A11y role="button" description={`Click to travel to view ${''}!`}>
            <mesh
              scale={[1, 1, 1]}
              onClick={handleClick}
              onPointerUp={handleClick}
              onPointerOver={() => {
                if (item.value?.room?.display) {
                  setToolTip(item.value.room.display);
                }
              }}
              onPointerOut={() => {
                setToolTip('');
              }}
            >
              <boxBufferGeometry args={[40, 90, 2]} />
              <meshBasicMaterial color={0x000000} />
            </mesh>
        </A11y>
      </group>
    </Suspense>
  );
}

export default Door;