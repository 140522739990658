import {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { cockpitUrl } from "../../../../utils/cockpit";

const PageItemSectionKeypadButtonImage = (props) => {

  const { button } = props;
  const { value } = button;
  const { image } = value;

  if (image?.path) {
    return (
      <img
        className="page__item__keypad__button__image"
        src={cockpitUrl + image.path}
        alt={image.title}
      />
    );
  } else {
    return null;
  }
}

const PageItemSectionKeypadButton = (props) => {

  const { button, rooms, pages2DOnly } = props;
  const { value } = button;
  const { linkType, link, roomLink, page2DOnlyLink } = value;
  const [linkPath, setLinkPath] = useState('');

  useEffect(() => {
    if (linkType === '2D Only Page' && pages2DOnly && pages2DOnly[0] && page2DOnlyLink && page2DOnlyLink._id) {
      let selectedPage;
      for (let page of pages2DOnly) {
        if (page._id === page2DOnlyLink._id) {
          selectedPage = page;
        }
      }
      if (selectedPage?._id) {
        setLinkPath(`/page/${selectedPage.title_slug && selectedPage.title_slug !== '' ? selectedPage.title_slug : selectedPage._id}`);
      } else {
        setLinkPath('');
      }
    } else if (linkType === 'room' && rooms && rooms[0] && roomLink && roomLink._id) {
      let selectedRoom;
      for (let room of rooms) {
        if (room._id === roomLink._id) {
          selectedRoom = room;
        }
      }
      if (selectedRoom?._id) {
        setLinkPath(`/room/${selectedRoom.title_slug && selectedRoom.title_slug !== '' ? selectedRoom.title_slug : selectedRoom._id}`);
      } else {
        setLinkPath('');
      }
    } else {
      setLinkPath('');
    }
  }, [rooms, pages2DOnly, linkType, link, roomLink, page2DOnlyLink]);

  return (
    <div className="col-xxs-3 no-padding">
      {
        ((linkType === 'room' || linkType === '2D Only Page') && linkPath !== '') &&
        <Link
          to={linkPath}
          className="page__item__keypad__button"
        >
          <PageItemSectionKeypadButtonImage {...props} />
        </Link>
      }
      {
        linkType === 'external' && link && link !== '' &&
        <a
          href={link}
          className="page__item__keypad__button"
          rel="noreferrer"
          target="_blank"
        >
          <PageItemSectionKeypadButtonImage {...props} />
        </a>
      }
      {
        ((linkType === 'external' && (!link || link !== '')) || ((linkType === 'room' || linkType === '2D Only Page') && linkPath === '') || linkType === 'none' || !linkType) &&
        <span
          className="page__item__keypad__button"
          style={{pointerEvents: 'none'}}
        >
          <PageItemSectionKeypadButtonImage {...props} />
        </span>
      }
    </div>
    )
}

const PageItemSectionKeypad = (props) => {

  const { item } = props;

  if (item.settings.buttons && item.settings.buttons[0]) {
    return (
      <div className="page__item__keypad row no-margins"
        style={{
          maxWidth: item.settings.width ? parseFloat(item.settings.width) * 4 + 'px' : null
        }}
      >
        {
          item.settings.buttons.map(
            (button, index) => (
              <PageItemSectionKeypadButton
                key={index}
                button={button}
                {...props}
              />
            )
          )
        }
      </div>
    );
  } else {
    return null;
  }
}

export default PageItemSectionKeypad;