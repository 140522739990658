import MailchimpSignupForm from './MailchimpSignupForm'

const PageItemSectionMailchimpSignupForm = (props) => {


  return (
    <div className="col-xxs-12">
      <MailchimpSignupForm {...props} />
    </div>
  )
}

export default PageItemSectionMailchimpSignupForm;