import * as THREE from 'three';
import { useTexture } from '@react-three/drei';
import { getImage } from '../../../../utils/cockpit';
import { useState, useEffect } from 'react';

const NormalTexture = (props) => {

  const { normal } = props;
  const texture = useTexture(normal);

  if (texture.uuid) {
    return <primitive object={texture} attach="normalMap" />;
  } else {
    return null;
  }
}

const ImageTexture = (props) => {

  const { image } = props;
  const texture = useTexture(image);

  if (texture.uuid) {
    return <primitive
      object={texture}
      attach="map"
      encoding={THREE.sRGBEncoding}
    />;
  } else {
    return null;
  }
}

const AlphaTexture = (props) => {

  const { alpha } = props;
  const texture = useTexture(alpha);

  if (texture.uuid) {
    return <primitive object={texture} attach="alphaMap" />;
  } else {
    return null;
  }
}

const ShapeGeometry = (props) => {

  const { item, room, roomHeight } = props;
  const { value } = item;
  const { shape, width, height, depth } = value;

  if (shape === 'box') {
    return (
      <boxBufferGeometry
        args={[
          width / 100 * room.width,
          height / 100 * roomHeight,
          depth / 100 * room.depth
        ]}
      />
    );
  } else if (shape === 'sphere') {
    return (
      <sphereBufferGeometry
        args={[
          width / 2 / 100 * room.width,
          64,
          64
        ]}
      />
    );
  } else if (shape === 'cylinder') {
    return (
      <cylinderBufferGeometry
        args={[
          width / 100 * room.width,
          width / 100 * room.width,
          height / 100 * roomHeight,
          32
        ]}
      />
    );
  } else if (shape === 'donut') {
    return (
      <torusBufferGeometry
        args={[
          width / 100 * room.width / 2, height / 2, depth / 2, 64
        ]}
      />
    );
  } else {
    return null;
  }
}

const Shape = (props) => {
  const { positionX, positionY, positionZ, item } = props;
  const { value } = item;
  const { brightness, color, image, normalMap, alphaMap, rotation } = value;
  const [imageSrc, setImageSrc] = useState('');
  const [alphaSrc, setAlphaSrc] = useState('');
  const [normalSrc, setNormalSrc] = useState('');

  useEffect(() => {
    let isMounted = true;

    const handleGetImage = (url) => {
      if (isMounted === true) {
        setImageSrc(url);
      }
    }

    const handleGetAlpha = (url) => {
      if (isMounted === true) {
        setAlphaSrc(url);
      }
    }

    const handleGetNormal = (url) => {
      if (isMounted === true) {
        setNormalSrc(url);
      }
    }

    if (image && image.path) {
      getImage(image.path, 2048, 2048, 30, handleGetImage);
    }

    if (normalMap && normalMap.path) {
      getImage(normalMap.path, 2048, 2048, 30, handleGetNormal);
    }

    if (alphaMap && alphaMap.path) {
      getImage(alphaMap.path, 2048, 2048, 30, handleGetAlpha);
    }

    return () => {
      isMounted = false;
    }
  }, [image, normalMap, alphaMap]);

  return (
    <mesh
      position={[
        positionX,
        positionY,
        positionZ
      ]}
      rotation={[0, rotation, 0]}
      intensity={brightness / 100}
      color={color}
    >
      <ShapeGeometry {...props} />
      <meshPhongMaterial
        transparent={true}
        needsUpdate={props.isPreview}
      >
        {
          normalSrc !== '' &&
          <NormalTexture {...props} normal={normalSrc} />
        }
        {
          imageSrc !== '' &&
          <ImageTexture {...props} image={imageSrc} />
        }
        {
          alphaSrc !== '' &&
          <AlphaTexture {...props} alpha={alphaSrc} />
        }
      </meshPhongMaterial>
    </mesh>
  )
}

export default Shape;