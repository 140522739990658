import * as THREE from 'three';
import { useEffect } from 'react';

const RoomWallsEnvMap = ({ material }) => {

  useEffect(() => {
    let isMounted = true;
    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    const ctx = canvas.getContext('2d');

    // Create gradient
    var grd = ctx.createRadialGradient(100, 100, 0, 100, 100, 100);
    grd.addColorStop(0.0, 'black');
    grd.addColorStop(0.25, '#222');
    grd.addColorStop(0.5, 'black');
    grd.addColorStop(0.75, '#222');
    grd.addColorStop(1.0, 'black');

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, 200, 200);

    const dataUrl = canvas.toDataURL();
    new THREE.TextureLoader().load(
      dataUrl,
      (reflectionTexture) => {
        if (material.current && isMounted === true) {
          material.current.envMap = reflectionTexture;
          material.current.envMap.mapping = THREE.EquirectangularReflectionMapping;
        }
      }
    );

    return () => {
      isMounted = false;
    }

  }, [material]);

  return null
};

export default RoomWallsEnvMap;