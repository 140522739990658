import { useRef, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { cockpitUrl } from '../../../../utils/cockpit';

const ModelContents = (props) => {

  const {
    value, itemIndex, isHovered, setWidth, setHeight, setDepth, isGardenItem, room,
    width, height, depth, scaleMultiplier, setScaleMultiplier, boxBounds, setBoxBounds, isNight, sceneIsLoaded
  } = props;
  
  const { positionX, positionY, positionZ, rotation } = props;
  const { modelPath, scale, glowInTheDark } = value;
  const model = useGLTF(cockpitUrl + '/' + modelPath);
  const [modelClone, setModelClone] = useState({});
  const modelMesh = useRef();
  const box = useRef();
  const light = useRef();

  useEffect(() => {
    let raf;

    if (itemIndex) { }

    if (model.scene) {
      const bounds = {
        min: {},
        max: {}
      };

      const traverseMesh = () => {

        if (model.scene) {
          model.scene.traverse(
            function (child) {
              if (child.isMesh) {
                if (typeof child.material.originalEmissive === 'undefined') {
                  child.material.originalEmissive = { ...child.material.emissive };
                  child.material.originalColor = { ...child.material.color };
                  child.material.originalEmissiveIntensity = child.material.emissiveIntensity;
                  child.material.originalMetalness = child.material.metalness;
                  child.material.originalRoughness = child.material.roughness;
                }
                if (glowInTheDark === true && isNight === true) {
                  child.material.emissive = { r: 0.63671875, g: 0.953125, b: 0.62109375 };
                  child.material.color = { r: 0.63671875, g: 0.953125, b: 0.62109375 };
                  child.material.emissiveIntensity = 0.2;
                  child.material.metalness = 0.09;
                  child.material.roughness = 0;
                } else {
                  child.material.emissive = { ...child.material.originalEmissive };
                  child.material.color = { ...child.material.originalColor };
                  child.material.emissiveIntensity = child.material.originalEmissiveIntensity;
                  child.material.metalness = child.material.originalMetalness;
                  child.material.roughness = child.material.originalRoughness;
                }
                child.geometry.computeBoundingBox();
                if (child.geometry.boundingBox?.min && child.geometry.boundingBox?.max) {
                  const { min, max } = child.geometry.boundingBox;
                  if (!bounds.min.x || min.x < bounds.min.x) {
                    bounds.min.x = min.x;
                  }
                  if (!bounds.min.y || min.y < bounds.min.y) {
                    bounds.min.y = min.y;
                  }
                  if (!bounds.min.z || min.z < bounds.min.z) {
                    bounds.min.z = min.z;
                  }
                  if (!bounds.max.x || max.x > bounds.max.x) {
                    bounds.max.x = max.x;
                  }
                  if (!bounds.max.y || max.y > bounds.max.y) {
                    bounds.max.y = max.y;
                  }
                  if (!bounds.max.z || max.z > bounds.max.z) {
                    bounds.max.z = max.z;
                  }
                }
              }
            }
          );
          setBoxBounds(bounds);
          const x = Math.abs(bounds.max.x - bounds.min.x);
          const y = Math.abs(bounds.max.y - bounds.min.y);
          const z = Math.abs(bounds.max.z - bounds.min.z);
          let multiplier = scaleMultiplier;
          if (x !== 0) {
            multiplier = 40 / x;
            setScaleMultiplier(multiplier);
          }
          setWidth(scale * multiplier * x);
          setHeight(scale * multiplier * y);
          setDepth(scale * multiplier * z);
        } else {
          raf = requestAnimationFrame(traverseMesh);
        }
      }

      raf = requestAnimationFrame(traverseMesh);
      if (sceneIsLoaded === true) {
        setModelClone(model.scene.clone());
      }
    }
    box.current = new THREE.Box3();

    return () => {
      cancelAnimationFrame(raf);
    }

  }, [setModelClone, model.scene, scale, scaleMultiplier, setWidth, setHeight, setDepth, itemIndex, setBoxBounds, setScaleMultiplier, isGardenItem, isNight, glowInTheDark, sceneIsLoaded]);

  useEffect(() => {
    if (modelMesh.current) {
      modelMesh.current.traverse(
        function (child) {
          if (child.isMesh) {
            if (isHovered === true && child.material.emissive) {
              if (child.material.emissive) {
                child.material.defaultColor = { ...child.material.color };
              }
            } else {
              if (child.material.defaultColor) {
                child.material.emissive = { r: 0, g: 0, b: 0 };
                child.material.emissiveIntensity = 0;
              }
            }
          }
        }
      );
    }
    if (light.current) {
      if (value.function && typeof value.function !== 'undefined') {
        if (isHovered === true) {
          light.current.intensity = 1;
        } else {
          if (room?.mainLightBrightness) {
            light.current.intensity = room.mainLightBrightness / 1000;
          } else {
            light.current.intensity = 0;
          }
        }
      }
    }
  }, [isHovered, isGardenItem, isNight, glowInTheDark, value.function, room]);

  if (modelClone.uuid) {
    return (
      <group position={[positionX, value.allowOutOfBounds !== true ? positionY : value.positionY, positionZ]} >
        {
          boxBounds.min && props.isDev === true &&
          <mesh
            rotation={[0, value.onWall === true ? (Math.ceil(value.rotation / 90) * 90) / 180 * Math.PI : 0, 0]}
          >
            <boxBufferGeometry args={[width, height, depth]} />
            <meshPhongMaterial transparent={true} opacity={0.6} />
          </mesh>
        }
        <group
          scale={[scale * scaleMultiplier, scale * scaleMultiplier, scale * scaleMultiplier]}
          rotation={[0, rotation ? rotation : 0, 0]}
        >
          <pointLight
            ref={light}
            intensity={room?.mainLightBrightness ? room.mainLightBrightness / 200 : isNight === true ? 0 : 0.4}
            color={room?.mainLightColor ? room.mainLightColor : 0xffffff}
            castShadow={true}
          />
          {
            isNight === true && glowInTheDark === true &&
            <pointLight
              castShadow={true}
              intensity={1}
              decay={2}
            />
          }
          <mesh
            position={
              value.allowOutOfBounds !== true ?
                [
                  boxBounds.min ? ((boxBounds.min.x + boxBounds.max.x) * -0.5) : 0,
                  boxBounds.min ? ((boxBounds.min.y + boxBounds.max.y) * -0.5) : 0,
                  boxBounds.min ? ((boxBounds.min.z + boxBounds.max.z) * -0.5) : 0
                ]
                :
              [
               0, 0, 0
              ]
            }
            rotation={[0, value.onWall === true ? (Math.ceil(value.rotation / 90) * 90) / 180 * Math.PI : 0, 0]}
            ref={modelMesh}
          >
            <primitive object={modelClone} />
          </mesh>
        </group>
      </group>
    );
  } else {
    return null;
  }
}

const Model = (props) => {
  const { item } = props;
  const { value } = item;
  const { modelPath } = value;

  if (modelPath) {
    return (
      <ModelContents {...props} item={item} value={value} />
    );
  } else {
    return null;
  }
}

export default Model;