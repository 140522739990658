import { Link } from 'react-router-dom';

const Header = (props) => {

  return (
    <header className="header">
      <h1 className="title">
        <Link to="/" className="title__link">Claire Barrow</Link>
      </h1>
    </header>
  )
};

export default Header;