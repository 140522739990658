import { useState, useEffect } from 'react';
import { cockpitUrl, getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, w, h, q, isBackgroundImage, desaturate, fullscreen } = props;
  const [imageSrcLarge, setImageSrcLarge] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    let isMounted = true;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (image.path) {
      if (image.path.substring(image.path.length - 4).toLowerCase() === '.gif') {
        const url = cockpitUrl + image.path;
        img = document.createElement('img');
        img.addEventListener('load', onImageLoad);
        img.src = url;
        setImageSrcLarge(url);
      } else {
        const path = image.path;
        if (isMounted === true) {
          getImage(
            path,
            w ? w : 2500,
            h ? h : 2500,
              q ? q : 30,
            (imageUrl) => {
              const url = imageUrl;
              if (isMounted === true) {
                setImageSrcLarge(url);
                img = document.createElement('img');
                img.addEventListener('load', onImageLoad);
                img.src = url;
              }
            },
            desaturate ? ['desaturate'] : false
          );
        }
      }
    }

    return () => {
      isMounted = false;
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image.path, w, h, q, desaturate]);

  if (image.path && image.path !== '') {
    if (isBackgroundImage === true) {
      return (
        <div
          className={`background-image--lazyload${imageIsLoaded === true ? ' loaded' : ''}`}
          style={{
            backgroundImage: `url(${imageSrcLarge})`
          }}
        />
      )
    } else {
      return (
        <img
          className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${fullscreen === true ? ' cover' : ''}`}
          src={imageSrcLarge}
          alt={image.title}
        />
      )
    }
  } else {
    return null
  }
}

export default LazyloadImage;