import WysiwygContent from "../../WysiwygContent";

const PageItemSectionText = (props) => {

  const { item } = props;

  if (item.settings.text) {
    return (
      <div className="col-xxs-12">
        <WysiwygContent {...props} content={item.settings.text} />
      </div>
    );
  } else {
    return null;
  }
}

export default PageItemSectionText;