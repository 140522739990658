import { useState, useEffect, Fragment, useCallback } from 'react';
import { fetchCockpitData } from './utils/cockpit';
import Content from './components/Content';
import { Helmet } from 'react-helmet';

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [isDev] = useState(window.location.href.indexOf('http://localhost') === 0 ? true : false);
  const [instructionsAreActive, setInstructionsAreActive] = useState(true);
  const [instructionsHaveShownOnce, setInstructionsHaveShownOnce] = useState(false);
  const [userIsTouching, setUserIsTouching] = useState(false);
  const [isHoldingPage, setIsHoldingPage] = useState(window.location.search !== '?preview=true' && isDev === false && window.location.origin !== 'https://clairebarrow.netlify.app' ? true : false);
  const [previewItem, setPreviewItem] = useState({});
  const [isPreview] = useState(props.location.search.indexOf('?preview=true') > -1 ? true : false);
  const [isPreviewStyleNav, setIsPreviewStyleNav] = useState(props.location.search.indexOf('?preview=true') > -1 ? true : false);
  const [rooms, setRooms] = useState([]);
  const [pages2DOnly, setPages2DOnly] = useState([]);
  const [is2DOnlyPage, setIs2DOnlyPage] = useState(false);
  const [mapIsActive, setMapIsActive] = useState(false);
  const [siteOptions, setSiteOptions] = useState({});
  const [homepage, setHomepage] = useState({});
  const [garden, setGarden] = useState({});
  const [is3D, setIs3D] = useState(true);
  const [focusedItem, setFocusedItem] = useState({});
  const [hoveredItem, setHoveredItem] = useState({});
  const [toolTip, setToolTip] = useState('');
  const [backgroundMusic, setBackgroundMusic] = useState('');
  const [siteIsMuted, setSiteIsMuted] = useState(false);
  const [overlayContent, setOverlayContent] = useState({});

  useEffect(() => {
    setOverlayContent({});
  }, [is3D, props.location.pathname]);

  useEffect(() => {
    if (siteOptions.holdingPage === true) {
      props.history.push('/');
    }
  }, [siteOptions.holdingPage, props.history]);

  useEffect(() => {
    if (props.location.pathname.indexOf('/page/') === 0) {
      setIs3D(false);
    }
  }, [props.location.pathname]);

  const fetchAllContent = useCallback(() => {
    if (isHoldingPage === false) {
      fetchCockpitData('rooms', setRooms, true, null, {
        "published": true
      });
      fetchCockpitData('pages2DOnly', setPages2DOnly, true, null, {
        "published": true
      });
    } else {
      fetchCockpitData('rooms', setRooms, true, null, {
        "published": true,
        "showOnHoldingPage": true
      });
      fetchCockpitData('pages2DOnly', setPages2DOnly, true, null, {
        "published": true,
        "showOnHoldingPage": true
      });
    }

    const handleGetHomepage = (data) => {
      setHomepage(data[0]);
    }
    fetchCockpitData('homepage', handleGetHomepage, true, null, {
      "published": true
    });
    fetchCockpitData('siteOptions', setSiteOptions, false);

    const handleGetGarden = (data) => {
      setGarden(data[0]);
    }
    fetchCockpitData('gardenItems', handleGetGarden, true, null, {
      "published": true
    });
  }, [isHoldingPage]);

  useEffect(() => {
    let isMounted = true;

    const handleGetSiteOptions = (data) => {
      setSiteOptions(data);
      if (typeof data.holdingPage !== 'undefined' && (isHoldingPage === true || isHoldingPage === false)) {
        if (window.location.search !== '?preview=true' && isDev === false && window.location.origin !== 'https://clairebarrow.netlify.app' && isMounted === true) {
          setIsHoldingPage(data.holdingPage);
          if (data.holdingPage === true) {
            setIs3D(true);
          }
        }
      }
    }
    fetchCockpitData('siteOptions', handleGetSiteOptions, false);

    return () => {
      isMounted = false;
    }
  }, [isDev, isHoldingPage]);

  useEffect(() => {
    fetchAllContent();
  }, [fetchAllContent]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    const handleTouchstart = () => {
      setUserIsTouching(true);
    }
    window.addEventListener('touchstart', handleTouchstart);

    const handleWheel = () => {
      setUserIsTouching(false);
    }
    window.addEventListener('wheel', handleWheel);

    const handleClick = (e) => {
      if (e.touches) {
        if (e.touches.length > 0) {
          setUserIsTouching(true);
        }
      } else if (e.sourceCapabilities?.firesTouchEvents === true) {
        setUserIsTouching(true);
      }
      else {
        setUserIsTouching(false);
      }
    }
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('touchstart', handleTouchstart);
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('click', handleClick);
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title key="title">{siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow'}</title>
        <meta property="title" content={siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow'} />
        <meta property="og:title" content={siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow'} />
        <meta property="twitter:title" content={ siteOptions?.seoTitleDefault ? siteOptions.seoTitleDefault : 'Claire Barrow' } />
        
        <meta property="description" content={siteOptions?.seoDescriptionDefault ? siteOptions.seoDescriptionDefault : 'Claire Barrow'} />
        <meta property="og:description" content={siteOptions?.seoDescriptionDefault ? siteOptions.seoDescriptionDefault : 'Claire Barrow'} />
        <meta property="twitter:description" content={siteOptions?.seoDescriptionDefault ? siteOptions.seoDescriptionDefault : 'Claire Barrow'} />
      </Helmet>
      <Content
        pathname={props.location.pathname}
        windowDimensions={windowDimensions}
        {...windowDimensions}
        rooms={rooms}
        setRooms={setRooms}
        pages2DOnly={pages2DOnly}
        setPages2DOnly={setPages2DOnly}
        garden={garden}
        setGarden={setGarden}
        mapIsActive={mapIsActive}
        setMapIsActive={setMapIsActive}
        isPreview={isPreview}
        homepage={homepage}
        setHomepage={setHomepage}
        is3D={is3D}
        setIs3D={setIs3D}
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
        focusedItem={focusedItem}
        setFocusedItem={setFocusedItem}
        hoveredItem={hoveredItem}
        setHoveredItem={setHoveredItem}
        siteOptions={siteOptions}
        setSiteOptions={setSiteOptions}
        isHoldingPage={isHoldingPage}
        setIsHoldingPage={setIsHoldingPage}
        is2DOnlyPage={is2DOnlyPage}
        setIs2DOnlyPage={setIs2DOnlyPage}
        isPreviewStyleNav={isPreviewStyleNav}
        setIsPreviewStyleNav={setIsPreviewStyleNav}
        userIsTouching={userIsTouching}
        instructionsAreActive={instructionsAreActive}
        setInstructionsAreActive={setInstructionsAreActive}
        instructionsHaveShownOnce={instructionsHaveShownOnce}
        setInstructionsHaveShownOnce={setInstructionsHaveShownOnce}
        toolTip={toolTip}
        setToolTip={setToolTip}
        backgroundMusic={backgroundMusic}
        setBackgroundMusic={setBackgroundMusic}
        siteIsMuted={siteIsMuted}
        setSiteIsMuted={setSiteIsMuted}
        overlayContent={overlayContent}
        setOverlayContent={setOverlayContent}
        {...props}
      />
      {/* dev only */}
      {
        isDev === true &&
        <button onClick={fetchAllContent} style={{ position: 'fixed', zIndex: 999, top: 0, left: 0 }}>Fetch Content</button>
      }
    </Fragment>
  );
}

export default App;
