import { useRef } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import groundSeamless from '../../../../assets/textures/mud/Ground_wet_003_basecolor.jpg';
import groundNormalSeamless from '../../../../assets/textures/mud/Ground_wet_003_normal.jpg';
import groundRoughnessSeamless from '../../../../assets/textures/mud/Ground_wet_003_roughness.jpg';
import groundAoSeamless from '../../../../assets/textures/mud/Ground_wet_003_ambientOcclusion.jpg';
import groundAlphaSeamless from '../../../../assets/textures/mud/Ground_wet_003_alpha.jpg';

const MudMaterial = (props) => {

  const { width, isArray } = props;

  const groundTexture = useTexture(groundSeamless);
  const groundNormal = useTexture(groundNormalSeamless);
  const groundRoughness = useTexture(groundRoughnessSeamless);
  const groundAo = useTexture(groundAoSeamless);
  const groundAlpha = useTexture(groundAlphaSeamless);

  const mesh = useRef();
  const map = useRef();
  const normalMap = useRef();
  const roughnessMap = useRef();
  const aoMap = useRef();
  const alphaMap = useRef();

  return (
    <meshPhysicalMaterial
      attachArray={isArray === true ? "material" : null}
      ref={mesh}
      color={"white"}
      roughness={0}
      reflectivity={0.9}
      transparent={true}
      side={THREE.DoubleSide}
      needsUpdate={true}
    >
      <primitive
        object={groundTexture}
        ref={map}
        attach={`map`}
        wrapS={THREE.RepeatWrapping}
        wrapT={THREE.RepeatWrapping}
        repeat={width / 20}
        needsUpdate={true}
      />
      <primitive
        object={groundNormal}
        ref={normalMap}
        attach={`normalMap`}
        wrapS={THREE.RepeatWrapping}
        wrapT={THREE.RepeatWrapping}
        repeat={width / 20}
      />
      <primitive
        object={groundRoughness}
        ref={roughnessMap}
        attach={`roughnessMap`}
        wrapS={THREE.RepeatWrapping}
        wrapT={THREE.RepeatWrapping}
        repeat={width / 20}
      />
      <primitive
        object={groundAo}
        ref={aoMap}
        attach={`aoMap`}
        wrapS={THREE.RepeatWrapping}
        wrapT={THREE.RepeatWrapping}
        repeat={width / 20}
      />
      <primitive
        object={groundAlpha}
        ref={alphaMap}
        attach={`alphaMap`}
        wrapS={THREE.RepeatWrapping}
        wrapT={THREE.RepeatWrapping}
        repeat={width / 20}
      />
    </meshPhysicalMaterial>
  );
}

export default MudMaterial;