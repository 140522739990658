import PageItemSection from './PageItems/PageItemSection';

const PageLayoutGridView = (props) => {

  const { windowDimensions, setIs3D, item } = props;
  const { windowHeight } = windowDimensions;
  const { content2DLayoutGrid } = item;

  return (
      <div
        className="layout-grid-with-vertical-scroll__content view--layout-grid-with-vertical-scroll container-fluid"
        style={{
          height: windowHeight + 'px'
        }}
      >
        <div className="section row">
          <div className="col-xxs-12">
            {
              content2DLayoutGrid &&
              content2DLayoutGrid[0] &&
              content2DLayoutGrid.map(
                (section, i) => (
                  <PageItemSection {...props} key={i} section={section} setIs3D={setIs3D} isDirectlyInsideColumn={true} />
                )
              )
            }
          </div>
        </div>
      </div>
  )
}

export default PageLayoutGridView;