import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

const WysiwygContent = (props) => {

  const { content, pages2DOnly, rooms } = props;
  const [parsedContent, setParsedContent] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (content) {
      const contentSplit = content.split('collection://');
      let contentConcatenated = '';
      for (let i = 0; i < contentSplit.length; i++) {
        if (i > 0) {
          const collection = contentSplit[i].substring(0, contentSplit[i].indexOf('/'));
          const stringAfterCollectionHref = contentSplit[i].substring(contentSplit[i].indexOf('"'));
          const id = contentSplit[i].substring(contentSplit[i].indexOf('/') + 1, contentSplit[i].indexOf('"'));
          let slug = '';
          if (collection === 'pages2DOnly') {
            slug = `/page/${id}`;
          } else if (collection === 'rooms') {
            slug = `/room/${id}`;
          } else {
            slug = '/';
          }
          contentConcatenated += slug + stringAfterCollectionHref;
        } else {
          contentConcatenated += contentSplit[i];
        }
      }
      setParsedContent(contentConcatenated);
    }
  }, [content, pages2DOnly, rooms]);

  const handleClick = (e) => {
    if (e.target.closest('a')) {
      if (e.target.closest('a').href.indexOf(window.origin) === 0 || e.target.closest('a').href.indexOf('/') === 0) {
        e.preventDefault();
        history.push(e.target.closest('a').href.replace(window.origin, ''));
      } else {
        e.preventDefault();
        window.open(e.target.closest('a').href);
      }
    }
  }

  if (parsedContent !== '') {
    return (
      <div
        onClick={handleClick}
      >
        {parse(parsedContent)}
      </div>
    );
  } else {
    return null;
  }
};

export default WysiwygContent;