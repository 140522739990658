import { useEffect } from 'react';

const ItemOutOfBoundsPositioner = (props) => {

  const { setPositionX, setPositionZ, setRotation, item, room, width, height, depth, isPreview, previewItem } = props;
  const { value } = item;
  const { positionX, positionZ, rotation } = value;

  useEffect(() => {
    let roomDimensions = {
      width: width,
      height: 70,
      depth: depth
    }
    if (isPreview === true && previewItem.collection === 'gardenItems') {
      roomDimensions.width = 600;
      roomDimensions.height = 170;
      roomDimensions.depth = 600;
    }
    const xPosition = positionX;
    setPositionX(xPosition);
    const zPosition = positionZ;
    setPositionZ(zPosition);
    setRotation(rotation / 180 * Math.PI);
  }, [width, height, depth, positionX, positionZ, setPositionX, setPositionZ, setRotation, room.width, room.depth, rotation, isPreview, previewItem.collection]);

  return null;
}

export default ItemOutOfBoundsPositioner;