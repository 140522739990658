import { useRef, useState, useEffect } from 'react';
import { A11y } from '@react-three/a11y';
import Door from './RoomContentItems/Door';
import Light from './RoomContentItems/Light';
import Shape from './RoomContentItems/Shape';
import Model from './RoomContentItems/Model';
import Video from './RoomContentItems/Video';
import Image from './RoomContentItems/Image';
import { cockpitUrl } from '../../../utils/cockpit';
import ItemOnWallPositioner from './ItemOnWallPositioner';
import ItemFreePositioner from './ItemFreePositioner';
import ItemVerticalPositioner from './ItemVerticalPositioner';
import ItemOutOfBoundsPositioner from './ItemOutOfBoundsPositioner';

const RoomContentItemContent = (props) => {

  const { item } = props;

  switch(item.field.name) {
    case 'image':
      return <Image {...props} />
    case 'video':
      return <Video {...props} />
    case 'model':
      return <Model {...props} />
    case 'door':
      return <Door {...props} />
    case 'light':
      return <Light {...props} />
    case 'shape':
      return <Shape {...props} />
    default:
      return null;
  }
}

const RoomContentItemA11yWrapper = (props) => {

  const { value, field, setToolTip, setIsHovered, handleItemClick, setHoveredItem, focusedItemInfo } = props;
  const [description, setDescription] = useState('');

  useEffect(() => {
    const theFunction = value.function[0];
    const type = theFunction.field.name;

    if (type === 'externalLink') {
      setDescription(`Visit link`);
    } else if (type === 'room') {
      setDescription(theFunction.value.display ? theFunction.value.display : '');
    } else if (type === 'page') {
      setDescription(theFunction.value.display ? theFunction.value.display : '');
    } else if (type === 'video') {
      setDescription(`Watch video`);
    } else if (type === 'videoEmbed') {
      setDescription(`Watch video`);
    } else if (type === 'text') {
      setDescription(`Read text`);
    } else if (type === 'image') {
      setDescription(`View image`);
    }
  }, [value.function, field]);

  return (
    <A11y role="button" description={``}>
      <group
        onClick={handleItemClick && handleItemClick}
        onPointerUp={handleItemClick && handleItemClick}
        onPointerOver={() => {
          setHoveredItem && setHoveredItem({ ...focusedItemInfo });
          setToolTip(description);
          setIsHovered(true);
        }}
        onPointerOut={() => {
          setHoveredItem && setHoveredItem({});
          setToolTip('');
          setIsHovered(false);
        }}
      >
        <RoomContentItemContent {...props} description={description} />
      </group>
    </A11y>
  );
}

const RoomContentItemInner = (props) => {

  const { onWall, item, allowOutOfBounds } = props;

  return (
    <group>
      {
        onWall === true || item.field.name === 'door' ?
          <ItemOnWallPositioner {...props} />
          :
          allowOutOfBounds !== true ?
            <ItemFreePositioner {...props} />
            :
            <ItemOutOfBoundsPositioner {...props} />
      }
      <ItemVerticalPositioner {...props} />
      {
        item.value.function && Object.prototype.toString.call(item.value.function) === '[object Array]' && item.value.function[0] && item.value.function[0].value ?
          <RoomContentItemA11yWrapper {...props} value={item.value} />
          :
          <RoomContentItemContent {...props} value={item.value} />
      }
    </group>
  );
}

const RoomContentItem = (props) => {

  const { item, rooms, pages2DOnly, setOverlayContent } = props;
  const { onWall } = item.value;
  const [focusedItemPosition, setFocusedItemPosition] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [depth, setDepth] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [scaleMultiplier, setScaleMultiplier] = useState(12);
  const [boxBounds, setBoxBounds] = useState({});
  const audio = useRef();
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, []);

  const handleItemClick = (e) => {

    if (item.value?.function) {
      const theFunction = item.value.function[0];
      if (theFunction?.value) {
        const type = theFunction.field.name;
        if ((type === 'video' || type === 'videoEmbed' || type === 'image' || type === 'text') && isMounted.current === true) {
          setOverlayContent({
            "type": type,
            "value": theFunction.value
          });
        } else if (type === 'room') {
          if (theFunction.value._id && rooms && rooms[0]) {
            let roomLink = '';
            const id = theFunction.value._id;
            for (let room of rooms) {
              if (room._id === id) {
                roomLink = `/room/${room.title_slug && room.title_slug !== '' ? room.title_slug : room._id}`;
              }
            }
            if (roomLink !== '' && props.isPreview === false) {
              props.history.push(roomLink);
            }
          }
        } else if (type === 'page') {
          if (theFunction.value._id && pages2DOnly && pages2DOnly[0]) {
            let pageLink = '';
            const id = theFunction.value._id;
            for (let page of pages2DOnly) {
              if (page._id === id) {
                pageLink = `/page/${page.title_slug && page.title_slug !== '' ? page.title_slug : page._id}`;
              }
            }
            if (pageLink !== '' && props.isPreview === false) {
              props.history.push(pageLink);
            }
          }
        } else if (type === 'externalLink' && theFunction.value) {
          window.open(theFunction.value)
        } else if (type === 'sound' && theFunction.value) {
          if (!audio.current) {
            audio.current = document.createElement('audio');
          }
          audio.current.src = cockpitUrl + '/' + theFunction.value;
          audio.current.play().catch();
        }
      }
    } else {
      if (item.field.name === 'image' && item.value?.image?.path && isMounted.current === true) {
        setOverlayContent({
          "type": 'image',
          "value": item.value.image
        });
      }
    }
  }

  if (item.value) {
    return (
      <RoomContentItemInner
        {...props}
        handleItemClick={handleItemClick}
        onWall={onWall}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        focusedItemPosition={focusedItemPosition}
        setFocusedItemPosition={setFocusedItemPosition}
        scaleMultiplier={scaleMultiplier}
        setScaleMultiplier={setScaleMultiplier}
        width={width}
        setWidth={setWidth}
        height={height}
        setHeight={setHeight}
        depth={depth}
        setDepth={setDepth}
        positionX={positionX}
        setPositionX={setPositionX}
        positionY={positionY}
        setPositionY={setPositionY}
        positionZ={positionZ}
        setPositionZ={setPositionZ}
        rotation={rotation}
        setRotation={setRotation}
        boxBounds={boxBounds}
        setBoxBounds={setBoxBounds}
        allowOutOfBounds={item.value.allowOutOfBounds}
      />
    );
  } else {
    return null;
  }
}

export default RoomContentItem;