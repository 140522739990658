import { useEffect } from 'react';
import { useProgress } from '@react-three/drei';

const Loader = (props) => {

  const { setSceneIsLoaded } = props;

  const { progress } = useProgress();

  useEffect(() => {
    let raf;

    const checkProgress = () => {
      if (progress === 100) {
        setSceneIsLoaded(true);
      } else {
        raf = requestAnimationFrame(checkProgress);
      }
    }

    raf = requestAnimationFrame(checkProgress);

    return () => {
      cancelAnimationFrame(raf);
    };
  }, [progress, setSceneIsLoaded]);

  return null
}

export default Loader;