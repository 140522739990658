const PageItemSectionHeading = (props) => {

  const { item } = props;

  return (
    <div className="col-xxs-12">
      <h2>{item.settings.text}</h2>
    </div>
  )
}

export default PageItemSectionHeading;