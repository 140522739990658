import LazyloadImage from '../../../LazyloadImage';

const PageItemSectionImage = (props) => {

  const { item } = props;

  if (item.settings?.image) {
    return (
      <div className="col-xxs-12 page__item__image__wrapper">
        <LazyloadImage image={item.settings.image} />
      </div>
    );
  } else if (item.path && item.path !== '') {
    return <LazyloadImage image={item} q={90} />;
  } else {
    return null;
  }
}

export default PageItemSectionImage;