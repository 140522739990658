import GardenContentsItem from './GardenContentsItem';

const GardenContents = (props) => {
  const { project } = props;

  return (
    <group>
      {
        project &&
        project.content &&
        project.content.map(
          (item, i) => (
            <GardenContentsItem item={item} key={i} index={i} room={project} {...props} />
          )
        )
      }
    </group>
  )
}

export default GardenContents;