import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import LazyloadImage from '../../../LazyloadImage';

const PageItemSectionCustomImageInner = (props) => {
  const { item, children, rooms, pages2DOnly } = props;
  const { settings } = item;
  const { linkType, link, roomLink, page2DOnlyLink } = settings;
  const [linkPath, setLinkPath] = useState('');

  useEffect(() => {
    if (linkType === '2D Only Page' && page2DOnlyLink && page2DOnlyLink._id) {
      if (page2DOnlyLink._id) {
        setLinkPath(`/page/${page2DOnlyLink.title_slug && page2DOnlyLink.title_slug !== '' ? page2DOnlyLink.title_slug : page2DOnlyLink._id}`);
      } else {
        setLinkPath('');
      }
    } else if (linkType === 'room' && roomLink && roomLink._id) {
      if (roomLink._id) {
        setLinkPath(`/room/${roomLink._id}`);
      } else {
        setLinkPath('');
      }
    } else {
      setLinkPath('');
    }
  }, [rooms, pages2DOnly, linkType, link, roomLink, page2DOnlyLink]);

  if (!linkType || linkType === 'none') {
    return (<Fragment>{children}</Fragment>);
  } else if (linkType === 'external' && link !== '') {
    return (
      <a
        className="page__item__image__link"
        rel="noreferrer"
        target="_blank"
        href={link}
      >{children}</a>
    );
  } else if (linkType === 'room' && linkPath !== '') {
    return (
      <Link
        className="page__item__image__link"
        to={linkPath}
      >{children}</Link>
    );
  } else if (linkType === '2D Only Page' && linkPath !== '') {
    return (
      <Link
        className="page__item__image__link"
        to={linkPath}
      >{children}</Link>
    );
  } else {
    return (<Fragment>{children}</Fragment>);
  }
}

const PageItemSectionImage = (props) => {

  const { item } = props;

  if (item.settings?.image) {
    return (
      <div
        className={`col-xxs-12 page__item__image__wrapper${item.settings.stretchToFitContainer === false ? ' small' : ''}${item.settings.align ? ` ${item.settings.align}` : `` }`}
        style={{
          width: item.settings.stretchToFitContainer === false && item.settings.customPixelWidth && item.settings.customPixelWidth !== '' ? item.settings.customPixelWidth + 'px' : null
        }}
      >
        <PageItemSectionCustomImageInner {...props}>
          <LazyloadImage image={item.settings.image} />
        </PageItemSectionCustomImageInner>
      </div>
    );
  } else if (item.path && item.path !== '') {
    return <LazyloadImage image={item} />;
  } else {
    return null;
  }
}

export default PageItemSectionImage;