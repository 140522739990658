import { Switch, Route } from 'react-router-dom';
import Main3D from '../Main3D';
import Main2D from '../Main2D';
import Preview from '../utils/Preview';
import DimensionSwitch from './DimensionSwitch';
import MapWrapper from './Map/Map';
import MapButton from './Map/MapButton';
import Page from './2D/Page/Page';
import { AnimatePresence } from 'framer-motion';
import Header from './Header';
import Music from './Music';

const Content = (props) => {

  const { windowDimensions, is3D, isPreview, isHoldingPage, isDev, pages2DOnly, previewItem } = props;

  return (
    <div className="app">
      <div
        className="main"
        style={{
          height: windowDimensions.windowHeight + 'px'
        }}
      >
        {
          is3D === true && props.location.pathname.indexOf('/page/') !== 0 ?
            <Main3D {...props} />
            :
            <Main2D {...props} />
        }
        <Switch>
          {
            pages2DOnly &&
            pages2DOnly[0] &&
            pages2DOnly.map(
              (item, i) => (
                <Route
                  exact
                  path={`/page/${item.title_slug}`}
                  key={i}
                  render={() => (
                    <Page
                      item={item}
                      {...props}
                      is2DOnlyPage={true}
                    />
                  )}
                />
              )
            )
          }
          {
            pages2DOnly &&
            pages2DOnly[0] &&
            pages2DOnly.map(
              (item, i) => (
                <Route
                  exact
                  path={`/page/${item._id}`}
                  key={i + 'id'}
                  render={() => (
                    <Page
                      item={item}
                      {...props}
                      is2DOnlyPage={true}
                    />
                  )}
                />
              )
            )
          }
        </Switch>
        {
          previewItem.collection === 'pages2DOnly' &&
          <Page
            item={previewItem}
            {...props}
            is2DOnlyPage={true}
          />
        }
        {
          (isHoldingPage === false || isDev === true) &&
          props.location.pathname.indexOf('/page/') !== 0 &&
          previewItem.collection !== 'pages2DOnly' &&
          previewItem.collection !== 'gardenItems' &&
          <DimensionSwitch {...props} />
        }
        {
          (isHoldingPage === false || isDev === true) &&
          <MapButton {...props} />
        }
        {
          <Header
            {...props}
          />
        }
        <AnimatePresence>
          <MapWrapper {...props} />
        </AnimatePresence>
      </div>
      {
        isPreview === true &&
        <Preview
          {...props}
        />
      }
      <Music {...props} />
    </div>
  )
}

export default Content;
